import 'fancybox/dist/js/jquery.fancybox';
import Analytics from '../helpers/analytics';



export default class {
    constructor() {     
        this.openLightBox();

        this.analytics = new Analytics();
    }

    openLightBox() {
        const self = this;
        $.fancybox.hideLoading();
        $('.open-video').fancybox({
            transitionIn: 'fade',
            transitionOut: 'fade',
            speedIn: 200,
            speedOut: 100,
            maxWidth: 800,
            maxHeight: 600,
            fitToView: false,
            width: '70%',
            height: '70%',
            autoSize: false,
            closeClick: false,
            preload: true,
            padding: 0,
            beforeClose: function() {
                const category = this.element.data('ga-category');
                const action = this.element.data('ga-action');

                if(category !== undefined && action !== undefined) {
                    self.analytics.track(category, action, 'Close', 'Close');
                }
            }
        });

        $('.open-lightbox').fancybox({
            transitionIn: 'fade',
            transitionOut: 'fade',
            speedIn: 200,
            speedOut: 100,
            autoscale: true,
            closeClick: false,
            openEffect : 'none',
            closeEffect: 'none',
            overlayColor: '#2e2e2e',
            preload: true,
            padding: 0,
            margin: [22, 22, 22, 22],
            beforeShow : function(){
                if(this.element.hasClass('open-lightbox')) {
                    this.title =  "<h4>"+ $(this.element).data('title') + "</h4><p>" + $(this.element).data('content') + "</p>";                
                }
                else {
                    this.fitToView = false;
                    this.titleShow = false;
                }
            },
        });
    } 
}

            