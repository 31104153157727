
export default class {
    constructor() {

        this.CookieWarning();

    }

    CookieWarning() {
        //creates the cookie
        $(".close-cookie-warning").click((e) => {
            e.preventDefault();
            $(".cookie-warning").stop().slideUp(250);
            //$("body").removeClass("cookie-warning-show");

            var cookieWarning = true;
            if (cookieWarning != "" && cookieWarning != null) {
                this.setCookie("closedCookieWarning", cookieWarning, 365);
            }
        });

        this.checkCookie();
    }

    checkCookie() {
        var cookieWarning = this.getCookie("closedCookieWarning");
        if (cookieWarning == "") {

            $(".cookie-warning").addClass("show");
            // $("body").addClass("cookie-warning-show");
        }
    }

    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

}