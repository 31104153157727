
export default class {
    constructor() {

        this.expandedButton = $('.expand-button');
        this.expandedButtonClose15 = $('.expand-close-15');

        this.clickEvents();
    }

    clickEvents() {
        this.expandedButton.on('click', (e) => {
            e.preventDefault();
            const $el = $(e.currentTarget);
            const exanded = $el.hasClass('opened');
            const id = $el.data('expand-id');

            const is15 = $el.hasClass('open-15');

            if(exanded) {
                $el.removeClass('opened');
                $el.parent().removeClass('parent-opened');
                $(`.svg-half-bg-${id}`).removeClass("half-size");
  
                $(`#${id}`).removeClass('opened')
                            .find('.expander-content-wrapper')
                            .slideUp(400);    
                             
                if(!is15) {
                    $el.attr('data-ga-label', 'Expand');                               
                }
            }
            else {
                $el.addClass('opened');
                $el.parent().addClass('parent-opened');
                $(`.svg-half-bg-${id}`).addClass("half-size");
                $(`#${id}`).addClass('opened')
                             .find('.expander-content-wrapper')
                             .slideDown(400);
                

                             
                if(is15) {
                    $el.addClass('unvisible');

                    $(`[data-expand-close-id="${id}"]`).show();
                }
                else {
                    $el.attr('data-ga-label', 'Close');
                }
                
              }    

               
        });

        this.expandedButtonClose15.on('click', (e) => {
            e.preventDefault();
            const $el = $(e.currentTarget);
            const id = $el.data('expand-close-id');

            $el.hide();

            $(`#${id}`).removeClass('opened').find('.expander-content-wrapper').slideUp(400);
            $(`#${id}`).find('.expand-button').removeClass('opened').removeClass('unvisible');
            $(`#${id} > div`).removeClass('parent-opened');
            $(`.svg-half-bg-${id}`).removeClass("half-size");

            
        });
    }
}


