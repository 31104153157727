import CalculateDistance from '../lib/calculate-distance';
import googleLoader from '../lib/google-loader';

export default class {

    constructor() {        
        this.setEvents();
        this.loadMap();
    }

    setEvents() {
        var self = this;

        $('.map-20-panel > form').on('submit', function(event) {  
            event.preventDefault();
            self.updatemap(this.bounds, $(this).find('input').val());
        });

        $('.bases-filter > a').on('click', function(event) {
            self.filterBases($(this).data('type'), event);
        });

        $('.map-button').on('click', function(event) {       
            event.preventDefault();
            self.unselectMapLocation();
            self.viewOnMap($(this).closest('.location-cell').data('index'));
            $(this).find('a').addClass('active');
        });
        
        $('.details-button').on('click', function(event) {       
            event.preventDefault(); 
            $(this).toggleClass('open');
            var expandedContent = $(this).closest('.location-cell').find('.expanded-content');               
            if (expandedContent.is(':visible')) {
                expandedContent.slideUp();
            } else {
                expandedContent.slideDown();
            }            
        });

        $('.collapse-expanded-content > a').on('click', function(event) {
            event.preventDefault();
            $(this).closest('.location-cell').find('.expanded-content').toggle();
        })
    }

    unselectMapLocation() {
        $('.map-button').find('a').removeClass('active');
    }

    loadMap() {
        this.mapLocations = $('.map-20').data('locationsjson');
        this.calculateDistance = new CalculateDistance(this.mapLocations);
        this.allBases = this.mapLocations;
    
        googleLoader().then(google => {
            this.calculateDistance = new CalculateDistance(google);
            this.google = google;
            this.recruitmentMapOptions = {
                zoom: 5,
                center: new google.maps.LatLng(55.378051, -3.43597299999999),
                maxZoom: 20,
                mapTypeControl: false,
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    {"stylers": 
                        [
                            { "lightness": -7 }
                        ]
                    }
                ]
            };

            this.bounds = new google.maps.LatLngBounds();
            const mapCanvas = document.getElementsByClassName('gmap-canvas');

            if ($(mapCanvas).length > 0) {
                this.gMapM = new google.maps.Map(mapCanvas[0], this.recruitmentMapOptions);
                this.setMarkers(this.gMapM, this.markersM, this.bounds, false, true);
                this.gMap = new google.maps.Map(mapCanvas[1], this.recruitmentMapOptions);
                this.setMarkers(this.gMap, this.markers, this.bounds, false, false);
            }; 
        });
    }
    
    setMarkers(gMap, markers, bounds, zoom, mobile, adjustZoom ) {
        let google = this.google;
        this.clearMarkers(markers);
        markers = [];
        const image = {
            url: '/img/gmapicon.png',
            scaledSize: new google.maps.Size(21,32),
            origin: new google.maps.Point(0,0),
            anchor: new google.maps.Point(11, 32)
        };

        $.each(this.mapLocations, (index, value) => {
            let request = {
                placeId: value.GooglePlaceID
            },
            marker = new google.maps.Marker({
                map: gMap,
                icon: image,
                position: new google.maps.LatLng(value.Latitude, value.Longitude),
                lat: value.Latitude,
                lng: value.Longitude
            });
            this.markerSelect(gMap, marker, value);
            markers.push(marker);
        });

        if (mobile) {
            this.markersM = markers;
        }
        else {
            this.markers = markers;
        }   

        gMap.markers = markers;
        
        google.maps.event.trigger(gMap, 'resize');

        // On PostCode seach
        if (zoom) {

            $.each(markers, (index, value) => {
                if (value.map !== null) {
                    bounds.extend(value.getPosition());
                }
            });

            gMap.fitBounds(bounds);
            gMap.panToBounds(bounds);

            if (adjustZoom) {
                gMap.setZoom(gMap.getZoom() - 1);
            }
        }

        if (!mobile) {
            gMap.panBy(-250, 0);
        }         
    }

    clearMarkers(markers) {
        $.each(markers, (index, marker) => {
           marker.setMap(null);
        });
    }

    markerSelect(gMap, marker, data) {
        let google = this.google;
        google.maps.event.addListener(marker, 'click', () => {
            this.selected = data;
            this.showInfo = true;
            $('.gmap-canvas').addClass('opened');
            this.viewMarker(this.gMap, marker, false);
            this.viewMarker(this.gMapM, marker, true);

        });
    }

    viewMarker(gMap, marker, mobile) {
        setTimeout(() => {
            this.google.maps.event.trigger(gMap, 'resize');
            gMap.setCenter(marker.getPosition());
            gMap.setZoom(15);
            if(!mobile) {
                gMap.panBy(-250, 0);
            }
        }, 200);
    }

    viewOnMap(index) {
        const marker = this.markers[index];
        const markerM = this.markersM[index];
        this.viewMarker(this.gMap, marker, false);
        this.viewMarker(this.gMapM, markerM, true);                
    }

    updatemap(bounds, postCode) {
        let google = this.google;
        bounds = new google.maps.LatLngBounds();
        
        const getBases = async (retries = 0) => {
            if (this.calculateDistance) {
                try {
                    this.mapLocations = await this.calculateDistance.selectBasesInRange(postCode, this.allBases, 65);
                } catch(error) {
                    console.error(error);
                }
            } else {
                if (retries <= 50) {
                    setTimeout(async () => {
                        await getBases(retries + 1);
                    }, 200);
                }
            }
        }
        getBases();
         
        setTimeout(() => {
            this.setMarkers(this.gMap, this.markers, bounds, true, false, true);
            this.setMarkers(this.gMapM, this.markersM, bounds, true, true, true);
        }, 800);
    }

    filterBases(type, e) {
        e.preventDefault();            
        const $target = $(e.currentTarget);
        $('.bases-filter a').removeClass('active');
        this.unselectMapLocation()
        $target.addClass('active');
        let bases = [];

        if (type === 'all') {
            this.mapLocations = this.allBases;
        }
        else {
            $.each(this.allBases, (index, base) => {
                if (type === 'uk') {
                    if(base.IsUK) {
                        bases.push(base);
                    }
                }
                if (type === 'abroad') {
                    if(!base.IsUK) {
                        bases.push(base);
                    }
                }
            });

            this.mapLocations = bases;
        }

        let google = this.google;
        let bounds = new google.maps.LatLngBounds();

        this.setMarkers(this.gMap, this.markers, bounds, true, false, false);
        this.setMarkers(this.gMapM, this.markersM, bounds, true, true, false);

        switch(type) {
            case 'uk':                
                $('.location-cell[data-isuk="True"').show();
                //$('.location-cell[data-isuk="False"').hide();
                $('.location-cell[data-isuk="False"').each(function() {
                    $(this).hide();
                    $(this).find('.expanded-content').hide();
                });
                break;
            case 'abroad':
                //$('.location-cell[data-isuk="True"').hide();
                $('.location-cell[data-isuk="True"').each(function() {
                    $(this).hide();
                    $(this).find('.expanded-content').hide();
                });
                $('.location-cell[data-isuk="False"').show();
                break;
            default:
                $('.location-cell[data-isuk="True"').show();
                $('.location-cell[data-isuk="False"').show();
                break;
        }
    }
}
