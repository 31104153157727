export default class {
    constructor() {
        this.responsiveTable();
    }

    responsiveTable() {
        const $table = $('.text-module').find('table');

        if($table !== undefined && $table.length > 0) {
            $table.wrap('<div class="table-wrapper"></div>')
        }
    }
}