import RoleFavouriting  from './roleFavouriting';

export default class {

    constructor() {
        
        var self = this;
        
        $(window).on('resize', function() {
            self.setPayContainerHeights();
        });
        
        $('.roletype-toggle-checkbox').on('change', function() {
            self.setPayContainerHeights();
        });
        
        this.setPayContainerHeights();

        this.roleFavouriting = new RoleFavouriting();        
    }

    setPayContainerHeights() {
        $('.roleinfo-pay-container').each(function() {
            var containerPadding = parseInt($(this).find('.center-wrap').css('padding-top')) * 2;
            var height = 0.00;

            $(this).find('.center-wrap').each(function() {
                if ($(this).height() > height)
                    height = $(this).height();
            });

            $(this).children('div').each(function() {
                $(this).height(height + containerPadding);
            });
        });
    }
}