import '../lib/owl.carousel2/dist/owl.carousel';

/*
NOTE: We have implemented the temporary fix in here:
https://github.com/OwlCarousel2/OwlCarousel2/issues/1771
If we lose that change we may need to remove the mouse drag (mouseDrag: false)
*/

export default class {

    constructor() {
       
        var self = this;

        this.blockWrapper = $('.infocards-slides');

        this.blockWrapper.each(function() {
            $(this).on('initialized.owl.carousel', function() { 
                var container = $(this).closest('.infocards-container');
                self.enableDisableNavButtons(container);
            });            
            $(this).owlCarousel({
                dots: false,
                nav: false,
                autoWidth: true,
                onTranslated: function(me) {
                    var container = $(me.target).closest('.infocards-container');
                    self.enableDisableNavButtons(container);
                }
            });

            var slideHeight = 0;
            $(this).find('.infocards-slide-wrapper').each(function() {
                if ($(this).outerHeight() > slideHeight) {
                    slideHeight = $(this).outerHeight();
                }                
            });
            $(this).find('.infocards-slide-wrapper').each(function() {
                $(this).outerHeight(slideHeight);
            });      
                          
        });
                
        this.setClickEvents();

        $(window).on('resize', function() {
            $('.infocards-container').each(function () {
                self.enableDisableNavButtons($(this));
            });            
        }); 
    }

    setClickEvents() {
        var self = this;
        $('.owl-prev-custom').on('click', function() {
            self.prev($(this));
        });

        $('.owl-next-custom').on('click', function() {
            self.next($(this));
        });
    }

    prev(button) {
        if ($(this).hasClass('disabled'))
            return;
        var container = button.closest('.infocards-container');
        container.find('.infocards-slides').trigger('prev.owl.carousel');
        this.enableDisableNavButtons(container);
    }

    next(button) {
        if ($(this).hasClass('disabled'))
            return;
        var container = button.closest('.infocards-container');
        container.find('.infocards-slides').trigger('next.owl.carousel');
        this.enableDisableNavButtons(container);          
    }

    enableDisableNavButtons(container) {             
        var prevButton = container.find('.owl-prev-custom');
        var nextButton = container.find('.owl-next-custom');
        prevButton.removeClass('disabled');  
        nextButton.removeClass('disabled'); 

        var containerWidth = container.width();     
        var owlStage = container.find('.owl-stage');
        var owlWidth = parseFloat(owlStage.css('width'), 10);
        var owlStageTransform = owlStage.css('transform');
        var translateX = owlStageTransform == 'none' || owlStageTransform == undefined ? 0 :  -1 * owlStage.css('transform').split(/[()]/)[1].split(',')[4];
        
        if (translateX == 0)
            prevButton.addClass('disabled');

        if (owlWidth - translateX <= containerWidth)
            nextButton.addClass('disabled');

        // Hide if both disabled. Will need to handle screen resize...
        if (prevButton.hasClass('disabled') && nextButton.hasClass('disabled')) {
            container.find('.owl-nav-custom-buttonwrapper').hide();
        } else {
            container.find('.owl-nav-custom-buttonwrapper').show();
        }
    }

}
