import Utilities from '../helpers/utilities';


export default class {
    constructor() {
        this.utilities = new Utilities();
        this.typeChange = $('.role-details-role-toggle a');
        this.viewJobDetails = $('.view-job-details');
        this.cookieName = 'role-detais-page';

        this.regularRegister = $('.registration-page-2017');
        this.regularRegisterReserve = $('.registration-page-2017-reserve');

        this.tabEvents();

        this.cookieEvents();
    }

    tabEvents() {
        this.typeChange.on('click', (e) => {
            e.preventDefault();
            const $el = $(e.currentTarget);
            const id = $el.data('id');
            const type = $el.data('type');

            this.typeChange.addClass('active');
            $el.removeClass('active');

            if(type === 'regular') {
                this.regularRegister.removeClass('hidden');
                this.regularRegisterReserve.addClass('hidden');
            }
            else {
                this.regularRegister.addClass('hidden');     
                this.regularRegisterReserve.removeClass('hidden');     
            }

            $('.tab-content').fadeOut();
            $(`#${id}`).removeClass('hidden').fadeIn();


        });
    }

    cookieEvents() {
        this.selectJobType();
        this.checkCookieExist();
    }

    checkCookieExist() {
        const pageId = $('.role-details-module').data('page-id');
        const cookie = this.utilities.getCookie(`${this.cookieName}-${pageId}`);

        if(cookie !== undefined && cookie !== null && cookie !== '') {
            this.changeType(cookie);
        }
    }

    selectJobType() {
        this.viewJobDetails.on('click', (e) => {
            e.preventDefault();
            const $el = $(e.currentTarget);
            const type = $el.data('type');
            const pageId = $el.data('page-id');

            this.utilities.setCookie(`${this.cookieName}-${pageId}`, type);
            this.changeType(type);
        })
    }

    changeType(type) {
        $('.no-cookies').addClass('hidden').fadeIn();
        $(`[data-type=${type}]`).removeClass('hidden');
        $('.role-details-role-toggle').removeClass('hidden');

        if(type === 'reserve') {
            this.regularRegister.addClass('hidden').fadeIn();
            this.typeChange.addClass('active');
            $('.role-details-role-toggle').find(`[data-type=${type}]`).removeClass('active')
        }
    }
}