export default class {

    constructor() {                
        $('.videosingle-playbutton').on('click', function() {
            var videoLink = $(this).data('link');
            var html = '<iframe class="videosingle-video" frameborder="0" allow="autoplay; fullscreen" src="' + videoLink + '"></iframe>';
            $(this).after(html);
            $(this).hide();
        });
    }
}
