
export default class {

  constructor() {
    this.clickEvents();
  }

  clickEvents() {
    $(".btn-20-livechat").click(function (event) {     
        event.preventDefault();
        //$(".header-20 #k2c-live-chat a").click();
        $('#k2c-live-chat a').click();
    });   
  }

}