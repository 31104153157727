import Utilities from '../helpers/utilities';

export default class {

    constructor() {
        this.setColors();
        this.setEvents();  
        this.setModules();      
        let regorRes = new Utilities().getUrlVars()['time']; 
        if (regorRes === 'reserve' || regorRes === 'reserves') {
            $('.roletype-toggle-checkbox').trigger('click');
        }
    }

    setColors() {        
        if ($('.roletype-toggle-checkbox').is(':checked')) {
            $('.roletype-toggle').addClass('reserves');
        } else {
            $('.roletype-toggle').removeClass('reserves');
        }
    }

    setEvents() {
        var self = this;
        $('.roletype-toggle-checkbox').on('click', function() {
            self.setColors();
            self.setModules();
        });
    }

    setModules() {
        $('div[data-forregular]').hide();
        $('div[data-forreserve]').hide();
        if ($('.roletype-toggle-checkbox').is(':checked')) {
            $('div[data-forreserve="True"]').show();
        } else {
            $('div[data-forregular="True"]').show();            
        }
    }
}
