import '../lib/owl.carousel2';

export default class {

    constructor() {    

        this.breakpoint = 1440;

        var self = this;

        $('.applicationprocess-slides').each(function() {
            $(this).on('initialized.owl.carousel', function() { 
                var container = $(this).closest('.applicationprocess-container');
                self.updateSlider(container);
                self.heightAdjuster();
            });
            $(this).owlCarousel({
                items: 1,
                dots: false,
                nav: true,
                navText: [
                    '<div><img class="applicationprocess-nav" src="/assets/img/left-arrow-blue.svg" alt="blue left arrow large icon" /><img class="applicationprocess-nav-mobile" src="/assets/img/chevron-larger-left-blue.svg" alt="blue left arrow icon" /></div>', 
                    '<div><img class="applicationprocess-nav" src="/assets/img/right-arrow-blue.svg" alt="blue right arrow large icon" /><img class="applicationprocess-nav-mobile" src="/assets/img/chevron-larger-right-blue.svg" alt="blue right arrow icon" /></div>'
                ],
                onTranslated: function(e) {
                    var container = $(e.target).closest('.applicationprocess-container');
                    self.updateSlider(container);
                    self.heightAdjuster();
                }
            });
        });

        $('.applicationprocess-slider-item-counter').on('click', function() {
            self.counterClicked($(this));
        });

        $('.roletype-toggle-checkbox').on('click', function() {
            $('.applicationprocess-container').each(function() {
                self.updateSlider($(this));
            });
        });

        $('.roletype-toggle-checkbox').on('change', function() {
            // give the carousel a chance to mark the active slide
            setTimeout(self.heightAdjuster, 400);
        });

        $(window).on('resize', function() {
            setTimeout(self.heightAdjuster, 400);
        });
    }

    counterClicked(counter) {
        var container = counter.closest('.applicationprocess-container');
        var clicked = counter.html();
        var current = container.find('.owl-item.active').find('.applicationprocess-slide').data('itemcount');
        var diff = clicked - current;

        if (diff > 0) {
            for (var i = 0; i < diff; i++) {
                container.find('.applicationprocess-slides').trigger('next.owl.carousel');
            }
        } else if (diff < 0) {
            diff = -1 * diff;
            for (var i = 0; i < diff; i++) {
                container.find('.applicationprocess-slides').trigger('prev.owl.carousel');
            }
        }
    }

    updateSlider(container) {
        var currentSlide = container.find('.owl-item.active').find('.applicationprocess-slide').data('itemcount');
        if (currentSlide == undefined)
            currentSlide = 1;

        container.find('.applicationprocess-slider-item').removeClass('active');
        container.find('.applicationprocess-slider-item[data-itemcount="' + currentSlide + '"]').addClass('active');
    }

    heightAdjuster() {

        // Desktop is always the same height (this magic number is the same as in the CSS, which seems somewhat redundant now)
        if ($(window).width() >= this.breakpoint) {
            $('.applicationprocess-slides').height(840);
        } else {
            // Mobile heights need to change to remove any space between this module and the next
            $('.applicationprocess-container').each(function() {
                var slidesWrapper = $(this).find('.applicationprocess-slides');
                var currentSlide = $(this).find('.owl-item.active');
                if (currentSlide.length > 0 && currentSlide.height() > 0)    
                    slidesWrapper.height(currentSlide.height());                
            });
        }                
    }

}
