import { gsap } from "gsap";

export default class {    
    constructor() {
        gsap.config({trialWarn: false});
        //console.clear();
        this.createLights();
    }

    createLights() {

        let canvas = document.querySelector('#FlashingLights'),
                context = canvas.getContext('2d'),
                resolution = Math.min(window.devicePixelRatio || 1, 2),
                speedToDuration = gsap.utils.mapRange(1, 10, 200, 1),
                sizeObj = {width: window.innerWidth * resolution,
                                        height: window.innerHeight * resolution,								 
                                        maxWidth: 440 * resolution,
                                        maxHeight: 440 * resolution,
                                        rangeMinX : (window.innerWidth * 0.2) * resolution,
                                        rangeMaxX : (window.innerWidth * 0.8) * resolution
                                    },
                particleArray = [],
                twoPi = Math.PI * 2,
                spotlights = [
                    {
                        innerSize: 0,
                        size: 840,
                        color: '#94062D',
                        speed: 1,
                        brightness: 0.96
                    },
                    {
                        innerSize: 0,
                        size: 780,
                        color: '#003B74',
                        speed: 1,
                        brightness: 1
                    },
                    {
                        innerSize: 0,
                        size: 820,
                        color: '#94062D',
                        speed: 1,
                        brightness: 0.88
                    },
                    {
                        innerSize: 0,
                        size: 700,
                        color: '#003B74',
                        speed: 1,
                        brightness: 0.77
                    },
                    {
                        innerSize: 0,
                        size: 980,
                        color: '#94062D',
                        speed: 1,
                        brightness: 1
                    },
                    {
                        innerSize: 0,
                        size: 840,
                        color: '#003B74',
                        speed: 1,
                        brightness: 1
                    },
                    {
                        innerSize: 0,
                        size: 990,
                        color: '#94062D',
                        speed: 1,
                        brightness: 0.86
                    },
                    {
                        innerSize: 0,
                        size: 890,
                        color: '#003B74',
                        speed: 1,
                        brightness: 0.78
                    },
                ],
                getHomePos = (value, id, max) => {
                    let snapPosX = gsap.utils.snap(sizeObj.width/max, (id/max) * sizeObj.width);
                    let snapPosY = gsap.utils.snap(sizeObj.height/max, (id/max) * sizeObj.height);
                    let bounds = value == 'x' ? snapPosX : snapPosY;
                    return gsap.utils.random(bounds, bounds+100)
                },
                getRadius = value => gsap.utils.random(sizeObj.height/2, sizeObj.height),
                getAlphaRange = function (p) {
                    let margin = window.innerWidth * 0.1;
                    let minAlpha = 0.2;
                    //console.log(particleArray)
                    let alpha = 0;
                    //let size = 0;
                    //let maxSize = p.initSize + 100;
                    if(p.x1< sizeObj.rangeMinX || p.x1 > sizeObj.rangeMaxX) {
                        alpha = p.brightness;
                        //size = p.initSize;
                    }	else if(p.x1.between(sizeObj.rangeMinX, sizeObj.rangeMinX + margin)) {
                        alpha = gsap.utils.mapRange(sizeObj.rangeMinX, sizeObj.rangeMinX + margin, p.brightness, minAlpha, p.x1)
                        //size = gsap.utils.mapRange(sizeObj.rangeMinX, sizeObj.rangeMinX + margin, p.initSize, maxSize, p.x1)
                    } else if (p.x1.between(sizeObj.rangeMinX + margin, sizeObj.rangeMaxX - margin)) {
                        alpha = minAlpha;
                        //size = maxSize;
                    } else if (p.x1.between(sizeObj.rangeMaxX-margin, sizeObj.rangeMaxX)) {
                        alpha = gsap.utils.mapRange(sizeObj.rangeMaxX-margin, sizeObj.rangeMaxX, minAlpha, p.brightness, p.x1)
                        //size = gsap.utils.mapRange(sizeObj.rangeMaxX-margin, sizeObj.rangeMaxX, maxSize, p.initSize, p.x1)
                    } 
                    //console.log(size)
                    return alpha
                }; 
    
        let particleProp = gsap.getProperty(particleArray[0]);
        
        class Particle {
            
            constructor(x1, y1, r1, x2, y2, r2, colorStop1, colorStop2, globalAlpha, speed, radius, brightness) {
                this.x1 = x1;
                this.y1 = y1;
                this.r1 = r1;
                this.x2 = x2;
                this.y2 = y2;
                this.r2 = r2;
                this.colorStop1 = colorStop1;
                this.colorStop2 = colorStop2;
                this.globalAlpha = globalAlpha;
                this.speed = gsap.utils.clamp(1, 10, speed);
                this.initX = x1;
                this.initY = y1;
                this.initSize = r2;
                this.radius = radius;
                this.brightness = brightness;
            }
    
        }
    
        spotlights.forEach((c, i) => {
            
            let initX = getHomePos('x', i, spotlights.length);
            let initY = getHomePos('y', i, spotlights.length);
            let p = new Particle(initX, initY, c.innerSize, initX, initY , c.size, c.color, 'rgba(1,7,11,0)', c.brightness, c.speed, getRadius(), c.brightness);	
            particleArray.push(p);
                gsap.set(p, {
                    x1: initX,
                    y1: initY,
                    r1: p.r1,
                    x2: initX,
                    y2: initY,
                    r2: p.r2
                });
            wander(p, p.initX, p.initY);
        })
    
    
        function draw() {
            
            context.globalCompositeOperation = "screen";
            context.clearRect(0, 0, canvas.width, canvas.height);
            particleArray.forEach((particle, count) => {
                context.globalAlpha = particle.globalAlpha;			
                let radGrad = context.createRadialGradient(particle.x1,particle.y1,particle.r1,particle.x2,particle.y2,particle.r2);
                radGrad.addColorStop(0, particle.colorStop1);
                radGrad.addColorStop(1, particle.colorStop2);
                context.fillStyle = radGrad;	
                context.fillRect(0,0,canvas.width,canvas.height);			
            })
        }
    
        function wander (p, initX, initY) {
            
            const duration = gsap.utils.random(speedToDuration(p.speed), (speedToDuration(p.speed)+2));
            let tl = gsap.timeline({});
                tl.to(p,  {
                    duration: duration,
                    x1: "+=" + twoPi,
                    x2: "+=" + twoPi,
                    repeat: -1,
                    modifiers: {
                        x1:x => (p.initX) + (Math.cos(x) * p.radius),
                        x2:x => (p.initX) + (Math.cos(x) * p.radius),
                    },
                        ease: 'none'
                })
                    .to(p, {
                        duration: duration,
                        y1: "+=" + twoPi,
                        y2: "+=" + twoPi,
                        repeat: -1,
                        modifiers: {
                            y1: y => (p.initY) + (Math.sin(y) * p.radius),
                            y2: y => (p.initY) + (Math.sin(y) * p.radius)
                        },
                        ease: 'none'
                    }, 0)
                    .fromTo(p, {
                        globalAlpha: p.brightness
                    }, {
                    delay: gsap.utils.random(1, 5),
                        globalAlpha: 'random(0, 0.2)',
                        duration: gsap.utils.random(5, 10),
                        ease: 'sine.inOut',
                        repeat: -1,
                        yoyo: true
                }, 0).seek(100)	
            
        }
        window.onresize = function (e) {
            gsap.to(sizeObj, {
                width: window.innerWidth * resolution,
                height: window.innerHeight * resolution,
                    rangeMinX : (sizeObj.width * 0.2),
                    rangeMaxX : (sizeObj.width * 0.8)			
            })
    
            canvas.width = window.innerWidth * resolution;
            canvas.height = window.innerHeight  * resolution;
            
            particleArray.forEach((c, i) => {	
                let scaledValue =  Math.min(sizeObj.width/sizeObj.maxWidth, sizeObj.height/sizeObj.maxHeight);
                gsap.to(c, {
                    duration: 0.5,
                    r2: scaledValue > 1 ? c.initSize : scaledValue * c.initSize,
                    radius: getRadius
                })
                gsap.to(c, {
                    duration: speedToDuration(c.speed),
                    initX: getHomePos('x', i, spotlights.length),
                    initY: getHomePos('y', i, spotlights.length)
                })
            }) 
        };
        gsap.ticker.add(draw);
        window.onresize();
    }

}
