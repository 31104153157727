export default class {
    constructor() {   
        var self = this;
        self.animateWishes();
        $(window).on('resize scroll', function() {
            self.animateWishes();        
        });
    }   

    animateWishes() {
        var self = this;
        $('.animate-titles-wish').each(function() {
            var $titleBlock = $(this);
            if (self.animateWishInView($titleBlock)) {
                self.animateTitle($titleBlock);
            }
        });
    }

    animateWishInView($titleBlock) {

        var win = $(window);
        var viewport = {
            top: win.scrollTop(),
            left: win.scrollLeft()
        };
        viewport.right = viewport.left + win.width();
        viewport.bottom = viewport.top + win.height();

        var bounds = $titleBlock.offset();
        bounds.right = bounds.left + $titleBlock.outerWidth();
        bounds.bottom = bounds.top + $titleBlock.outerHeight();

        return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
    }

    animateTitle($titleBlock) {
        $($titleBlock).removeClass('animate-titles-wish');
        $($titleBlock).addClass('animate-titles');
    }
}