import Cookies from 'js-cookie'; //https://github.com/js-cookie/js-cookie

export default class {

    constructor() {

        var self = this;
        this.cookieName = 'roadblock';
        this.breakpoint = 1026; // $large

        this.clickEvents();
        this.showRoadblock();        
        this.setBodyTopMargin();
        
        $(window).on('resize', function(){
            self.setBodyTopMargin();
        });

        self.setBodyTopMargin();
    }

    setBodyTopMargin() {
        if ($(window).width() >= this.breakpoint) {
            var availableHeight = $('.roadblock-imagewrapper').height();
            var contentHeight = $('.roadblock-bodywrapper').height();            
            if (contentHeight < availableHeight) {
                var paddingTop = (availableHeight - contentHeight) / 2;
                $('.roadblock-bodywrapper').css('padding-top', paddingTop);
            }
        } else {
            $('.roadblock-bodywrapper').css('padding-top', 0);
        }    
    }

    clickEvents() {
        var self = this;
        $('.roadblock-closebutton').on('click', function() {
            self.dismissRoadblock();
        });
        $('#RoadblockDismissButton').on('click', function() {
            self.dismissRoadblock();
        });
    }

    getExpiry() {
        switch ($('.roadblock-modalwrapper').data('persistence'))
        {
            case 'Show every 24hrs':
                return { expires: 1 };
            case 'Show every 7days':
                return { expires: 7 };
            default:
                return { };
        }
    }

    dismissRoadblock() {
        this.setCookie(this.cookieName, 'dismissed', this.getExpiry());
        $('.roadblock-modalwrapper').hide();
    }

    showRoadblock() {
        if (!this.getCookie(this.cookieName)) {
            $('.roadblock-modalwrapper').show();        
        }        
    }

    setCookie(name, value, settings) {        
        Cookies.set(name, value, settings);
    }

    getCookie(name) {
        return Cookies.get(name);
    }
}
