import '../lib/owl.carousel2';

export default class {

    constructor() {
                
        var self = this;

        var previousWidth = $(window).width();
        var breakpoint = 1026;
        var navText = $('.videocarousel').hasClass('transback') 
            ? ['<div><img src="/assets/img/left-arrow-white.svg" alt="white left arrow large icon" /></div>', '<div><img src="/assets/img/right-arrow-white.svg" alt="white right arrow large icon" /></div>']
            : ['<div><img src="/assets/img/left-arrow-blue.svg" alt="blue left arrow large icon" /></div>', '<div><img src="/assets/img/right-arrow-blue.svg" alt="blue right arrow large icon" /></div>'];

        $('.videocarousel-slides').owlCarousel({
            onTranslated: function() { self.stopVideos()},
            items: 1,
            center: true,
            autoWidth: true,
            loop: true,
            margin: 93,
            dots: false,
            nav: true,            
            navText: navText
        });

        $('.videocarousel-slides-mobile').owlCarousel({
            onTranslated: function() { self.stopVideos()},
            items: 1,
            dots: true,
            nav: false
        });

        $('.videocarousel-slide-playbutton').on('click', function() {
            self.stopVideos();
            var videoLink = $(this).data('link');
            var html = '<iframe class="videocarousel-slide-video" frameborder="0" allow="autoplay; fullscreen" src="' + videoLink + '"></iframe>';
            $(this).after(html);
            $(this).hide();
        });

        $(window).on('resize', function(event) {
            var newWidth = $(window).width();
            if ((newWidth >= breakpoint && previousWidth < breakpoint) || (newWidth < breakpoint && previousWidth >= breakpoint)) {
                self.stopVideos();
            } 
            previousWidth = newWidth;
        });
    }

    stopVideos() {
        $('.videocarousel-slide-video').each(function() {
            $(this).prev().show();
            $(this).remove();
        });
    }
}
