const haversineDistance = (latlngA, latlngB, isMiles) => {
    const toRadian = angle => (Math.PI / 180) * angle;
    const distance = (a, b) => (Math.PI / 180) * (a - b);
    const RADIUS_OF_EARTH_IN_KM = 6371;

    let lat1 = latlngA[0];
    let lat2 = latlngB[0];
    const lon1 = latlngA[1];
    const lon2 = latlngB[1];

    const dLat = distance(lat2, lat1);
    const dLon = distance(lon2, lon1);

    lat1 = toRadian(lat1);
    lat2 = toRadian(lat2);

    // Haversine Formula
    const a =
        Math.pow(Math.sin(dLat / 2), 2) +
        Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.asin(Math.sqrt(a));

    let finalDistance = RADIUS_OF_EARTH_IN_KM * c;

    if (isMiles) {
        finalDistance /= 1.60934;
    }

    return finalDistance;
};

export default class DistanceCalculator {
    constructor(google) {
        this.google = google;
    }

    selectBasesInRange(postcode, bases, radiusInMiles) {
        return new Promise((resolve, reject) => {
            const geocoder = new google.maps.Geocoder();
        
            if (geocoder && postcode != 'undefined') {

            geocoder.geocode({ 'address': postcode }, (results, status) => {
                if (status == google.maps.GeocoderStatus.OK) {
                    const lat = results[0].geometry.location.lat();
                    const lng = results[0].geometry.location.lng();

                    const data = bases
                        .map(base => {
                            const dist = haversineDistance([lat, lng], [base.Latitude, base.Longitude], true);

                            return {
                                ...base,
                                DistanceFull: dist,
                                Distance: `${Math.round(dist)}M`
                            }
                        })
                        .filter(base => base.DistanceFull <= radiusInMiles)
                        .sort((a, b) => a.DistanceFull - b.DistanceFull);

                    resolve(data);
                } else {
                    reject(`Unable to fetch latitude and longitude for postcode '${postMessage}'`);
                }
            })

            }
        });
    }
}
