import GoogleMapsLoader from 'google-maps';

let googleCached;

export default () => {
    return new Promise(resolve => {
        if (googleCached) {
            resolve(googleCached);
        } else {
            GoogleMapsLoader.KEY = 'AIzaSyCfEcTP3VdbZLuesGYQLHQFB8c0cYXS7tQ';
            GoogleMapsLoader.VERSION = 'quarterly';
            GoogleMapsLoader.LIBRARIES = ['geometry', 'places'];
            GoogleMapsLoader.load(google => {
                googleCached = google;
                resolve(google);
            });
        }
    });
}
