export default class {
    constructor() {       
        this.clickEvents();
        this.wrapTables();
    }

    clickEvents() {

        $('.accordion-cell-title').on('click', function() {
            if ($(this).parent().hasClass('expand')) {
                $(this).parent().removeClass('expand');
                $(this).parent().children('.accordion-cell-body').stop().slideUp();
            } else {
                $(this).parent().parent().children(".accordion-cell").removeClass('expand');
                $(this).parent().parent().children(".accordion-cell").children('.accordion-cell-body').stop().slideUp();
                $(this).parent().addClass('expand');
                $(this).parent().children('.accordion-cell-body').stop().slideDown();
            }
        })        
    }

    wrapTables() {
        // Prevent line break on hyphen (two types of hyphens)
        $('.accordion-cell .accordion-cell-bodytext table td').each(function () {
            $(this).html(function(i, html){
                //var newHtml = html.replace(/-/g, '–&#8288;');
                return html.replace(/-/g, '–&#8288;').replace(/–/g, '–&#8288;'); 
            });
        }); 
        // Add table wrapper to allow scroll off screen
        $('.accordion-cell .accordion-cell-bodytext table').each(function () {
            $(this).wrap(function () {
                return '<div class="accordion-table-wrapper"></div>';
            });
        }); 
    }
    
}