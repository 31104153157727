import Analytics from '../helpers/analytics';
import Utilities from '../helpers/utilities';
import 'jquery-mask-plugin';

export default class {

    constructor() {
        this.firstLoad = true;
        this.analytics = new Analytics();
        this.utilities = new Utilities();

        if (this.utilities.smartLoad('.apply-select-role-form')) {
            this.applySelectRoleForm = $('.apply-select-role-form');            
            this.applyingFor = $('#ApplyingFor');
            this.applyingFor2 = $('#ApplyingFor2');
            this.applyingFor3 = $('#ApplyingFor3');
            this.applyingFor.prop('disabled', true);
            this.applyingFor2.prop('disabled', true);
            this.applyingFor3.prop('disabled', true);
            this.roleTypeButton = this.applySelectRoleForm.find('.select-time a');
            this.applyForm20();
            this.roleKnownOrNot();
            this.favOrNot();
            this.buildFavourites();
            this.timeChange();
            this.submitButtonState();
        }
    }
    
    buildFavourites() {
        let favouriteContainer = $('.apply-select-favourites');
        let cookie = this.utilities.getCookie('favourites');
        if (cookie !== undefined && cookie.length > 0) {
            let catHeader = '';
            cookie.split(',').sort().forEach(function(fav, i) {
                let attr = fav.replace('¬',',').split('|');
                if (attr[0] != catHeader) {
                    catHeader = attr[0];
                    favouriteContainer.append(`<div class="form-header form-header-mt favcathead" data-catid="${attr[2]}">${catHeader}:</div>`)
                }
                let el = 
                    `<div class="checkbox-container checkbox-container-mtsmall favrole" data-catid="${attr[2]}" data-roleid="${attr[3]}" data-rank="${attr[4]}"`
                    + `data-regular="${attr[5]}" data-regularopen="${attr[6]}" data-reserve="${attr[7]}" data-reserveopen="${attr[8]}">`
                    + `<input id="elig${i}" type="checkbox" />`
                    + `<label for="elig${i}">${attr[1]}</label>`                    
                    + '</div>';
                favouriteContainer.append(el);
            });
        } 

        if ($('.favrole').length > 0) {
            $('.favroles-exist').show();
            $('.favroles-dontexist').hide();
        } else {
            $('.favroles-exist').hide();
            $('.favroles-dontexist').show();
        }

        $('.select-regorres-fav').on('click', (e) => {
            e.preventDefault();            
            var $selection = $(e.currentTarget);
            $('.select-regorres-fav').removeClass('active');            
            $selection.addClass('active');   
            this.setFavourites(false);            
        });

        $('.favrole').find('input[type="checkbox"]').on('change', (e) => {
            e.preventDefault();
            let selected = $('.favrole').find('input[type="checkbox"]:checked').length;
            let nextButton = $(e.currentTarget).closest('form').find(':submit');
            nextButton.prop('disabled', !(selected > 0) );
            if (selected > 2) {
                $('.favrole').find('input[type="checkbox"]:not(:checked)').prop('disabled', true);
            } else {
                this.setFavourites(true);
            }
        });

        this.setFavourites(false);
    }

    setFavourites(keepChecks) {

        if (!keepChecks)
            $('.favrole').find('input').prop('checked', false);

        $('.favrole').find('input').prop('disabled', true);

        var selectedType = $('.select-regorres-fav.active').data('time');
        if (selectedType != undefined && selectedType != '') {
            $('.favrole').each(function() {
                let $this = $(this);                
                if (selectedType == 'regular' && $this.data('regular') == 'True') {
                    $this.find('input').prop('disabled', false);
                } else if (selectedType == 'reserve' && $this.data('reserve') == 'True') {
                    $this.find('input').prop('disabled', false);
                }
            });
        }

        this.setFavouriteCategoryHeaders();
    }

    setFavouriteCategoryHeaders() {
        $('.favcathead').each(function () {
            let $this = $(this);
            var catId = $this.data('catid');
            var catRoles = $('.favrole[data-catid=' + catId + ']').find('input:enabled');
            $this.toggleClass('disabled', catRoles.length < 1 || catRoles == undefined);
        });
    }

    setMultiApplyForFavs() {
        $('#MultiApplyFor').val('');
        let value = '';
        $('.favrole').each(function() {
            let $this = $(this);
            let checkbox = $this.find('input[type="checkbox"]:checked');
            if (checkbox.length > 0) {
                let favroleValue = $this.data('catid') + '|' + $this.data('roleid') + '|' + $('.select-regorres-fav.active').data('time');    
                value = value + ',' + favroleValue;
            }         
        });  
        if (value.length > 0)
            value = value.substring(1);
        $('#MultiApplyFor').val(value);
    }

    setMultiApplyForNonfavs() {        
        $('#MultiApplyFor').val('');
        let selectedTime = $('.select-time').find('.active').data('time');
        var value = this.applyingFor.val() + '|' + selectedTime;        
        if (this.applyingFor2.val() != '') {
            value += ',' + this.applyingFor2.val() + '|' + selectedTime;
        }
        if (this.applyingFor3.val() != '') {
            value += ',' + this.applyingFor3.val() + '|' + selectedTime;
        }
        $('#MultiApplyFor').val(value);
    }

    favOrNot() {
        $('.favornot').on('click', (e) => {
            e.preventDefault();

            let $selection = $(e.currentTarget);
            $('.favornot').removeClass('active');            
            $selection.addClass('active');            

            $('.apply-select-notfavourites').hide();            
            $('.apply-select-favourites').hide();
                
            if ($selection.data('favornot') == 'fav') {                
                $('.apply-select-favourites').show();
            } else {               
                $('.apply-select-notfavourites').show();                
            }
        });
    }

    applyForm20() {       
        this.submitEvent();        
    }

    submitButtonState() {
        $('#roleTbd').on('change', function() {         
            var submitButton = $(this).closest('form').find(':submit');
            if (this.checked) {
                submitButton.prop('disabled', false);        
            } else {
                submitButton.prop('disabled', true);        
            }    
        });

        this.applyingFor.on('change', function() {
            var submitButton = $(this).closest('form').find(':submit');
            if ($(this).val() != '') {
                submitButton.prop('disabled', false);        
            } else {
                submitButton.prop('disabled', true);        
            }               
        });
    }

    roleKnownOrNot() {
    
        if ($('.apply-select-roleknownornot').find('.question-text').text() == '') {
            $('.apply-select-roleknownornot').hide();
        } else {
            $('.apply-select-roleknownornot').show();
        }

        $('.select-roleknown a').on('click', (e)=> {
            e.preventDefault();
            var $target = $(e.currentTarget);
            var roleknown = $target.data('roleknown');
            
            $('.select-roleknown a').removeClass('active');
            $target.addClass('active');
            
            var roleKnownFollowingForm = $('.apply-select-knownrole');
            var roleUnknownFollowingForm = $('.apply-select-unknownrole');
            roleKnownFollowingForm.hide();
            roleUnknownFollowingForm.hide();

            if (roleknown == "Yes") {
                roleKnownFollowingForm.show();
            } else if (roleknown == "No") {
                roleUnknownFollowingForm.show();
            }
        });
    }

    timeChange() {

        const urlVrs = this.utilities.getUrlVars();  

        if (urlVrs !== undefined) {
            const timeSelected = urlVrs['time'];
            if (timeSelected !== undefined && timeSelected === 'regular') {
                $('.regular-time').addClass('active');
                this.applySelectRoleForm.find('.time-checkbox').val(timeSelected);                
            }
        }

        var selectForm = $('.apply-select-role-form');
        var action = selectForm.attr('action');

        this.roleTypeButton.on('click', (e) => {
            e.preventDefault();
            const $target = $(e.currentTarget);
            const time = $target.data('time');

            this.roleTypeButton.removeClass('active');
            $target.addClass('active');

            this.applySelectRoleForm.find('.time-checkbox').val(time);

            selectForm.attr('action', `${action}?time=${time}`);

            //Media operations hack
            this.hideMediaOperator();

            this.applyingFor.removeClass('form-disabled').prop('disabled', false);
            this.applyingFor2.removeClass('form-disabled').prop('disabled', false);
            this.applyingFor3.removeClass('form-disabled').prop('disabled', false);

            this.updateDropdowns();
        });

        //const media = this.applyingFor.find('option[value=9999]'); 
        //media.hide();               
    }

    hideMediaOperator() {
        /*const time = this.applySelectRoleForm.find('.time-checkbox').val();
        const media = this.applyingFor.find('option[value=9999]'); 
        const groupId = this.interestArea.find(":selected").val();
    
        if (time === 'reserve' && groupId === '17') {
            media.show();
        }
        else {
            media.hide();
        }*/
    }

    updateDropdowns() {
        let isRegulars = $('.regular-time').hasClass('active');
        let isReserves = $('.reserve-time').hasClass('active');        
        $('.gtm-select-apply-for').each(function() {
            $(this).find('optgroup').each(function() {
                let optgroup = $(this);               
                let show = false;
                optgroup.find('option').each(function() {
                    let option = $(this);
                    option.prop('selected', false);
                    if ((isRegulars && option.data('isregular') == 'True') || (isReserves && option.data('isreserve') == 'True')) {
                        show = true;
                        option.show();
                    } else {
                        option.hide();
                    }
                });
                if (show) {
                    optgroup.show();
                } else {
                    optgroup.hide();
                }
            });
        });
    }

    submitEvent() {
        
        this.applySelectRoleForm.on('submit', (e) => {
            
            e.preventDefault();

            const time = this.applySelectRoleForm.find('.time-checkbox').val();
            if (time !== undefined && time !== '' && time !== null) {       
                if (time === 'reserve') {
                    const label = 'Spare time reserve';
                    this.analytics.dataLayer('iproSpareTime', 'Choose your role', label, 'Form submit');
                }                
            }

            if ($('.favornot.active').data('favornot') == 'fav') {
                this.setMultiApplyForFavs();            
            } else {
                this.setMultiApplyForNonfavs();
            }
                    
            e.currentTarget.submit();
        })        
    }
}