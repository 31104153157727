import Cookies from 'js-cookie'; //https://github.com/js-cookie/js-cookie
import slugify from 'slugify'

export default class {
    constructor() {
        this.prefix = '_raf_';
        this.cookieSettings = {
            expires: 7,
            path: '/',
            secure: false
        };
        this.sessionCookieSettings = {
            path: '/',
            secure: false
        }
    }

    smartLoad(c) {
        return $('body, section, div, form, span, table, a, ul, li').hasClass(c) || $(c).length > 0;
    }

    setSessionCookie(name, value) {
        Cookies.set(`${this.prefix}${name}`, value, this.sessionCookieSettings);
    }

    setCookie(name, value) {
        console.log('creating cookie with default settings');
        Cookies.set(`${this.prefix}${name}`, value, this.cookieSettings);
    }

    getCookie(name) {
        return Cookies.get(`${this.prefix}${name}`);
    }

    removeCookie(name) {
        Cookies.remove(`${this.prefix}${name}`, { path: this.cookieSettings.path });
    }

    stringStartUper(value) {
        return value.replace(/\b\w/g, l => l.toUpperCase());
    }

    isMobile() {
        return $(window).width() < 768;
    }

    isTablet() {
        return $(window).width() < 1024;
    }

    isiOS() {
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return true;
        }
        return false;
    }

    //need this polyfil https://github.com/WebReflection/dom4
    iOSsmothScroll() {
        let supportsPassive = false;
        try {
            const opts = Object.defineProperty({}, 'passive', {
                get: function() {
                    supportsPassive = true;
                }
            });
            window.addEventListener("testPassive", null, opts);
            window.removeEventListener("testPassive", null, opts);
        } catch (e) {}

        // Use our detect's results. passive applied if supported, capture will be false either way.
        document.addEventListener('touchstart', () => {

        }, supportsPassive ? { passive: true } : false); 
    }

    ie11Check() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf('MSIE ');
        var trident = ua.indexOf('Trident/');
        if (msie > 0 || trident > 0) {
            return true;
        }
        else {
            return false;
        }      
    }

    findInArray(array, value) {
        return array.find(x => x.id === value);
    }

    
    findIndex(array, value) {
        return array.findIndex(x => x.id === value);
    }

    scrollTo($div, offset) {

        if(offset === undefined) {
            offset = 0;
        }
        if($div !== undefined && $div.length > 0) {
            $('html,body').animate({
                scrollTop: $div.offset().top - offset
            });
        }  
    }

    stringToSlug(str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();
      
        // remove accents, swap ñ for n, etc
        var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
        var to   = "aaaaaeeeeiiiioooouuuunc------";
    
        for (var i=0, l=from.length ; i<l ; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }
    
        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes
    
        return str;
    }

    getUrlVars(){
        let vars = []; 
        let hash;
        let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for(var i = 0; i < hashes.length; i++)
        {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars;
    }

    loadScriptAsync(scriptUrl) {
        $.ajax({
            url: scriptUrl,
            dataType: 'script',
            cache: true, // or get new, fresh copy on every page load
            success: function () {
                // Callback
            }
        });
    }

    loadJs(url) {
        var fileref = document.createElement('script');
        fileref.type = "text/javascript";
        fileref.src = url;
    }

    disableConsole(disable) {
        if(disable) {
            console.log = () => {};
            console.info = () => {};
            console.warning = () => {};
            console.error = () => {};
            console.message = () => {}; 
        }
    }
}