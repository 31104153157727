import lity from 'lity/dist/lity';

export default class {
    constructor() {
        this.dataLity = $('.lightbox-open');
        this.dataLityClose = $('.lightbox-close');
        this.instance = null;
        this.caption = '';
        this.events();
    }

    events() {
        this.dataLity.on('click', (e) => {
            e.preventDefault();
            const $target = e.currentTarget;
            const url = $($target).attr('href');
            this.caption = $($target).data('description');
            this.instance = lity(url);
        })

        $(document).on('lity:ready', (event, instance) => {
            let share = '<p class="lity-header">SHARE THIS</p>';
            let showShare = true;            
            let caption = '';
            
            if(this.caption !== undefined && this.caption != "") {
                console.log("penisz" + this.caption);
                caption = $(
                    '<div class="caption-close-container">' +
                    `<div class="caption">${this.caption}</div>`+
                    `<a href="#" class="lightbox-close"><span>Close</span></a>`+
                    '</div>'
                );
                
            }
            else {
                caption = $(
                    '<div class="caption-close-container no-caption">' +
                    `<div class="caption"></div>` +
                    `<a href="#" class="lightbox-close center"><span>Close</span></a>` +
                    '</div>'
                );
                showShare = false;
            }
            //Append after
            caption.appendTo($('body').find('.lity-content'));
            //Append Before
            if(showShare) {
                $('body').find('.lity-content').prepend(share);              
            }

            //Remove default close button
            $('body').find('.lity-close').remove();
            this.close();
        });
    }

    close() {
        this.dataLityClose = $('.lightbox-close');
        this.dataLityClose.on('click', (e) => {
            e.preventDefault();
            this.instance.close();
        });
    }
}