
export default class {
    constructor() {
        this.setEvents();
    }

    setEvents() {
        var img = $('.contactblock-label').find('img');
        $('.contactblock-label').on('click', function(e) {
            e.preventDefault();
            var block = $('.contactblock');
            if (block.is(':visible')) {
                block.slideUp();
                img.removeClass('rotate180');
            } else {
                block.slideDown(350);
                img.addClass('rotate180');
            }
        });
    }
}