export default class {

    constructor() {    
        this.currentStage = 1;
        this.getStage();
        this.setStage();
    }

    getStage() {
        var currentUrl = window.location.href;
        if (currentUrl.includes('applying-for'))
            this.currentStage = 2;
        else if (currentUrl.includes('thank-you'))
            this.currentStage = 4;
    }

    setStage() {
        var self = this;
        $('.applyprogress-slider-item').each(function() {
            var stageItem = $(this);
            var stageId = stageItem.data('itemcount');
            if (stageId < self.currentStage) {
                stageItem.addClass('done');
                stageItem.find('.applyprogress-slider-item-counter').hide();
                stageItem.find('.tickmark').show();
            }
            if (stageId == self.currentStage) {
                stageItem.addClass('active');
                return false;
            }
        });
    }
}
