import Utilities from '../helpers/utilities';

export default class {
    constructor() {
        this.tabedModule = $(".tabed-module");
        this.tab = $(".tabed-module .logo-container");
        this.content = $(".tabed-module .copy-container");
        this._utilities = new Utilities();

        this.pageOpenEvent();
        this.clickEvents();
    }

    pageOpenEvent() {
        const hash = window.location.hash.substr(1);
        if(hash !== undefined && hash !== '') {
            this.startChange(hash);
        }
        else {
            this.tabedModule.each((index, value) => {
                const $el = $(value);
                const moduleId = $el.attr('id');
                const cookie = this._utilities.getCookie(moduleId);
                if(cookie !== undefined && cookie !== '') {
                    this.startChange(cookie, moduleId);
                }
                
            }); 
        }
    }

    clickEvents() {
        this.tab.click((e) => {
            e.preventDefault();
            this.changeTab($(e.currentTarget));    
        });

    }

    startChange(hash, moduleId = 0) {
        this.tab.each((index, value) => {
            const $el = $(value);
            const type = $el.data('type');

            if(moduleId > 0) {
                const mId = $el.data('module-id');
                if(type === hash && moduleId === mId) {
                    this.changeTab($el);
                }
            }
            else {
                if(type === hash) {
                    this.changeTab($el);
                }
            }

            
        });
    }

    changeTab($el) {
        const type = $el.data('type');
        const moduleId = $el.data('module-id');

        this._utilities.setCookie(moduleId, type);
        window.location.hash = type;

        if ($el.hasClass("expand") == false) {

            /*var sideToShow = "left";
            var sideToHide = "right"
            if ($(this).hasClass("right")){
                sideToShow = "right";
                sideToHide = "left";
            }
            $(this).addClass("expand");*/
            this.tab.toggleClass("expand");
            this.content.stop().slideToggle(200);  
        }
    }
    
}