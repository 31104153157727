import Analytics from "../helpers/analytics";

export default class {
    constructor() {
        // pages data
        this.PAGE_ID_INTRO = "introPage";
        this.PAGE_ID_LOADING = "loadingPage";
        this.PAGE_ID_QUESTIONS = "questionsPage";
        this.PAGE_ID_RESULTS = "resultsPage";
        this.currentPage = this.PAGE_ID_INTRO;
        this.nextPage = null;
        this.init();
        // in-pages data
        this.INPAGE_ID_QUESTION = "questionInPage";
        this.INPAGE_ID_ANSWER = "answerInPage";
        this.currentInPage = this.INPAGE_ID_QUESTION;
        this.nextInPage = null;
        // intro messages
        this.INTRO_TOTAL_MESSAGES = 3;
        this.introVideoContainer;
        this.introMessageNow = 1;
        this.introVideoFinished = false;
        // questions data
        this.QUESTIONS_QUANTITY = 5;
        this.currentQuestion = 1;
        this.currentQuestionVideo = null;
        this.currentQuestionVideoContainer = null;
        this.currentQuestionVideoPlaying = true;
        this.questionShowingInstructions = true;
        this.questionTimerInterval = null;
        this.replayingQuestion = false;
        this.questionVideoRatio = 848 / 440;
        // results data
        this.SCORE_PER_CORRECT = 20;
        this.SCORE_PER_REPLAY = 3;
        this.correctAnswers = 0;
        this.replaysCount = 0;
        this.currentQuestionReplayed = false;
        this.individualReplaysCount = 0;
        this.tweetOutcome = "";
        this.pagebaseURL = [
            location.protocol,
            "//",
            location.host,
            location.pathname,
        ].join("");
        this.sharePageURL = this.pagebaseURL + "?score=";
        this.percResult = 0;

        this.loadingVideo = null;
        this.loadingVideoContainer = null;
        this.instructionsVideoContainer = null;

        // >> IMPORTANT! <<
        //  put all the possible answers without spaces and LOWERCASE, so it's easier to check afterwards - ie: "redlion"
        this.allQuestionsData = [
            {
                id: 1,
                video: "q-music",
                instructions: "Listen to the track",
                question: "Who was no average girl?",
                answers: [
                    "molly",
                    "moly",
                    "mollie",
                    "molli",
                    "moli",
                    "molee",
                    "mollee",
                    "molley",
                    "molle",
                    "molie",
                    "moley",
                ],
            },
            {
                id: 2,
                video: "q-festival",
                instructions: "Pay attention to your friend",
                question: "What's the number?",
                answers: ["761845"],
            },
            {
                id: 3,
                video: "q-football",
                instructions: "Concentrate on the commentator",
                question: "Who scored?",
                answers: [
                    "rossi",
                    "rossy",
                    "rosi",
                    "rosse",
                    "rosey",
                    "rosie",
                    "rossie",
                    "rosee",
                    "rossee",
                ],
            },
            {
                id: 4,
                video: "q-street",
                instructions: "Focus on the phone call",
                question: "What pub are you meeting in later?",
                answers: ["redlion", "theredlion", "redlyon", "theredlyon"],
            },
            {
                id: 5,
                video: "q-bar",
                instructions: "Zone into the two girls",
                question: "What kind of puppy did her mate get?",
                answers: [
                    "bulldog",
                    "buldog",
                    "bulldag",
                    "buldag",
                    "balldog",
                    "balldag",
                ],
            },
        ];
    }

    init() {
        $(document).ready(() => {
            this.ready();
        });
        $(window).on("load", () => {
            this.load();
        });
        $(window).on("resize", () => {
            this.onWindowResized();
        });
    }
    load() {
        this.analytics = new Analytics();
        this.onWindowResized();
    }
    ready() {
        var self = this;
        this.soundSkillsContainer = $("#soundskills");
        $('video').each(function () {
            makeVideoPlayableInline(this);
        });
        if (this.soundSkillsContainer.length > 0) {
            // this.soundSkillsContainer.find( "#beginButton" )[0].disabled = true;
            // fast variables
            this.introVideoContainer = this.soundSkillsContainer.find(
                "#introPage video"
            );
            this.loadingVideoContainer = this.soundSkillsContainer.find(
                "#loadingPage video"
            );
            this.loadingVideoContainer.data(
                "orig-max-height",
                this.loadingVideoContainer.css("max-height")
            );
            this.loadingVideo = this.loadingVideoContainer[0];
            this.instructionsVideoContainer = this.soundSkillsContainer.find(
                "#instructionsVideo"
            );
            this.instructionsVideoContainer.data(
                "orig-max-height",
                this.instructionsVideoContainer.css("max-height")
            );
            this.readyButton = this.soundSkillsContainer.find("#readyButton");
            this.playPauseButton = this.soundSkillsContainer.find("#playPauseButton");
            this.questionBottomArea = this.soundSkillsContainer.find(
                "#questionBottomArea"
            );
            this.answerBottomArea = this.soundSkillsContainer.find(
                "#answerBottomArea"
            );
            this.questionTimer = this.soundSkillsContainer.find("#questionTimer");
            this.questionInstructions = this.soundSkillsContainer.find(
                "#questionInstructions"
            );
            this.currentQuestionVideoContainer = this.soundSkillsContainer.find(
                "#questionVideo1"
            );
            this.soundSkillsContainer
                .find("#questionVideo1")
                .data(
                    "orig-max-height",
                    this.currentQuestionVideoContainer.css("max-height")
                );
            this.soundSkillsContainer
                .find("#questionVideo2")
                .data(
                    "orig-max-height",
                    this.currentQuestionVideoContainer.css("max-height")
                );
            this.soundSkillsContainer
                .find("#questionVideo3")
                .data(
                    "orig-max-height",
                    this.currentQuestionVideoContainer.css("max-height")
                );
            this.soundSkillsContainer
                .find("#questionVideo4")
                .data(
                    "orig-max-height",
                    this.currentQuestionVideoContainer.css("max-height")
                );
            this.soundSkillsContainer
                .find("#questionVideo5")
                .data(
                    "orig-max-height",
                    this.currentQuestionVideoContainer.css("max-height")
                );
            this.currentQuestionVideo = this.currentQuestionVideoContainer[0];
            this.currentQuestionAnswerInput = this.soundSkillsContainer.find(
                "#answerInPage input"
            )[0];

            // button binding
            this.soundSkillsContainer.find("#beginButton").bind("click", function (e) {
                self.onStartClicked(e);
            });
            this.playPauseButton.bind("click", function (e) {
                self.onTogglePlayClicked(e);
            });
            this.soundSkillsContainer
                .find("#replayQuestionButton")
                .bind("click", function (e) {
                    self.onReplayQuestionClicked(e);
                });
            this.soundSkillsContainer
                .find("#facebookButton")
                .bind("click", function (e) {
                    self.onShareFacebookClicked(e);
                });
            this.soundSkillsContainer
                .find("#twitterButton")
                .bind("click", function (e) {
                    self.onShareTwitterClicked(e);
                });
            this.soundSkillsContainer
                .find("#answerInPage input")
                .bind("input", function (e) {
                    self.onAnswerInputChanged(e);
                });
            this.soundSkillsContainer
                .find("#showAnswersButton")
                .bind("click", function (e) {
                    self.showAnswers(e);
                });
            this.soundSkillsContainer
                .find("#hideAnswersButton")
                .bind("click", function (e) {
                    self.hideAnswers(e);
                });

            // this.soundSkillsContainer.find( "#restartQuizButton" ).bind( 'click', this.onRestartQuizClicked );
            $(this.currentQuestionAnswerInput).on("blur", function () {
                //window.scrollTo(0, 0);
            });
        }
    }

    onWindowResized() {
        // do this once the item appears on screen, otherwise it uses the original percentage
        $("#soundskills").height(window.innerHeight - $("header").outerHeight());
        if (
            this.instructionsVideoContainer !== null &&
            this.instructionsVideoContainer.height() != 0
        ) {
            this.instructionsVideoContainer.css(
                "max-height",
                Math.round(
                    this.soundSkillsContainer.find("#loadingPage .middleArea").height() *
                    this.instructionsVideoContainer
                        .data("orig-max-height")
                        .substring(
                            0,
                            this.instructionsVideoContainer.data("orig-max-height").length -
                            1
                        ) /
                    100
                ) + "px"
            );
        }

        // check ratio of questionInPage and calculate video height and width as needed
        // this is to fix black bars on the side of the video that is set to 100% height and 100% width but the container is too wide for it
        if (this.currentQuestionVideoContainer.height() != 0) {
            this.currentQuestionVideoContainer.css("max-height", "100%");
        }

        this.soundSkillsContainer.find(".video-frame").each(function () {
            var frame = $(this),
                vid = frame.siblings("video:not(.videoVisible)").first(),
                cont = frame.closest(".middleArea");
            frame.css({
                width: vid.outerWidth(),
                height: vid.outerHeight(),
                left: vid.position().left,
                top: vid.position().top,
            });
        });

        // check ratio of questionInPage and calculate video height and width as needed
        // this is to fix black bars on the side of the video that is set to 100% height and 100% width but the container is too wide for it
        if (this.currentQuestionVideoContainer.height() != 0) {
            this.currentQuestionVideoContainer.css("max-height", "100%");
        }

        if (
            this.soundSkillsContainer.find("#questionInPage").width() /
            this.soundSkillsContainer.find("#questionInPage").height() >
            this.questionVideoRatio
        ) {
            this.currentQuestionVideoContainer
                .removeClass("questionVideoNormal")
                .addClass("questionVideoTooWide");
        } else {
            this.currentQuestionVideoContainer
                .removeClass("questionVideoTooWide")
                .addClass("questionVideoNormal");
        }
        // now that it's been fit properly, set the proper width or height to avoid black lines on the video
        if (this.currentQuestionVideoContainer.height() != 0) {
            this.currentQuestionVideoContainer.css(
                "max-height",
                Math.floor(this.currentQuestionVideoContainer.height()) + "px"
            );
        }
    }

    resetIntro() {
        if (this.introMessageNow != 1) {
            this.soundSkillsContainer
                .find("#rotatingMessage" + this.introMessageNow)
                .addClass("hiddenElement");
            this.makeElementDisappear(
                this.soundSkillsContainer.find(
                    "#rotatingMessage" + this.introMessageNow
                )
            );
            this.introMessageNow = 1;
            // show the first message
            this.makeElementAppear(
                this.soundSkillsContainer.find(
                    "#rotatingMessage" + this.introMessageNow
                )
            );
        }
    }

    reset() {
        this.readyButton
            .removeClass("shownElement")
            .removeClass("showElement")
            .addClass("hiddenElement");
        // +++++++++++++++++++++++++++++++++
        // halfways through implementing this, Ben decided (without telling anyone) that the restart functionality
        //  was to be removed - hence no need to continue. If this ever needs to be implemented what we are missing
        //  is resetting the question videos (hide the last one shown and show the first one)
        // +++++++++++++++++++++++++++++++++
        // reset the questions
        this.currentQuestion = 1;
        this.currentQuestionReplayed = false;
        this.currentQuestionVideoContainer = this.soundSkillsContainer.find(
            "#questionVideo1"
        );
        this.currentQuestionVideo = this.currentQuestionVideoContainer[0];
        this.correctAnswers = 0;
        this.replaysCount = 0;
        this.individualReplaysCount = 0;
        this.setQuestionData();
        this.makeElementAppear(
            this.soundSkillsContainer.find("#questionsPage #questionInPage")
        );
        this.soundSkillsContainer
            .find("#questionsPage #answerInpage")
            .removeClass("showElement")
            .removeClass("shownElement")
            .addClass("hiddenElement")
            .addClass("hideElement");
        this.currentInPage = this.INPAGE_ID_QUESTION;

        if (this.questionTimerInterval != null) {
            clearInterval(this.questionTimerInterval);
        }
        this.questionTimerInterval = null;
        this.soundSkillsContainer.find("#introVideo")[0].pause();
    }

    makeElementAppear(elem) {
        elem
            .removeClass("hiddenElement")
            .removeClass("hideElement")
            .addClass("shownElement")
            .addClass("showElement");
    }

    makeElementDisappear(elem) {
        elem.removeClass("showElement").addClass("hideElement");
        // if(!Modernizr.cssanimations) {
        //   // fallback for ie9
        //   elem.addClass( 'hiddenElement' );
        // }
    }

    onLoadingVideoTimeUpdate() {
        // need to swap the title depending on different breakpoints
        if (
            (this.introMessageNow == 1 && this.loadingVideo.currentTime >= 3) ||
            (this.introMessageNow == 2 && this.loadingVideo.currentTime >= 7)
        ) {
            // hide the previous message
            this.makeElementDisappear(
                this.soundSkillsContainer.find(
                    "#rotatingMessage" + this.introMessageNow
                )
            );
            // rotatingMessage1
            this.introMessageNow++;
            // show the current message
            this.makeElementAppear(
                this.soundSkillsContainer.find(
                    "#rotatingMessage" + this.introMessageNow
                )
            );
        }
        // and stop if we are showing the last one
		/*
		if( this.introMessageNow >= this.INTRO_TOTAL_MESSAGES ) {
		  var self = this;
		  this.loadingVideo.removeEventListener( 'timeupdate', function(){self.onLoadingVideoTimeUpdate();} );
		}
		*/

        if (!this.introVideoFinished && this.loadingVideo.currentTime >= 8) {
            this.onLoadingVideoFinished();
        }
    }

    onLoadingVideoFinished() {
        var self = this;
        if (!this.introVideoFinished) {
            this.introVideoFinished = true;
            this.loadingVideo.removeEventListener("timeupdate", function () {
                self.onLoadingVideoTimeUpdate();
            });
            this.loadingVideo.removeEventListener(
                "ended",
                function () {
                    self.onLoadingVideoFinished();
                },
                false
            );
            // make the button appear
            this.readyButton
                .removeClass("hiddenElement")
                .removeClass("hideElement")
                .addClass("showElement");
            this.readyButton.bind("click", function (e) {
                self.onReadyClicked(e);
            });
        }
    }

    onPageAppeared() {
        var self = this;
        switch (this.currentPage) {
            case this.PAGE_ID_INTRO:
                break;
            case this.PAGE_ID_LOADING:
                this.onWindowResized();
                this.loadingVideo.play();
                this.loadingVideo.addEventListener(
                    "ended",
                    function () {
                        self.onLoadingVideoFinished();
                    },
                    false
                );
                this.loadingVideo.addEventListener("timeupdate", function () {
                    self.onLoadingVideoTimeUpdate();
                });
                // reset the quiz
                this.reset();
                break;
            case this.PAGE_ID_QUESTIONS:
                this.onWindowResized();
                this.currentQuestionVideo.play();
                this.currentQuestionVideo.addEventListener(
                    "ended",
                    function () {
                        self.onQuestionVideoFinished();
                    },
                    false
                );
                if (this.questionTimerInterval != null) {
                    clearInterval(this.questionTimerInterval);
                }
                this.questionTimerInterval = setInterval(function () {
                    self.onQuestionVideoTimeUpdate();
                }, 1000);
                // using the video time update makes the timer go all weird
                // this.currentQuestionVideo.addEventListener( 'timeupdate', this.onQuestionVideoTimeUpdate );
                // add timer for the question number/instructions to disappear and swap for the timer/button
                this.makeElementDisappear(this.playPauseButton);
                this.makeElementAppear(this.questionInstructions);
                break;
            case this.PAGE_ID_RESULTS:
                this.makeElementAppear(
                    this.soundSkillsContainer.find("#findOutMoreFooter")
                );
                break;
        }
    }

    onPageDisappeared() {
        // now make it proper disappear
        this.soundSkillsContainer
            .find("#" + this.currentPage)
            .addClass("hiddenElement");
        // make current appear
        //console.log(this.nextPage)
        this.makeElementAppear(this.soundSkillsContainer.find("#" + this.nextPage));
        this.currentPage = this.nextPage;
        this.nextPage = null;
        // and now that a new page appeared, start that page
        this.onPageAppeared();
    }

    swapPage(newPage) {
        var self = this;
        this.nextPage = newPage;
        // make previous disappear
        this.soundSkillsContainer
            .find("#" + this.currentPage)
            .removeClass("showElement")
            .addClass("hideElement");
        setTimeout(function () {
            self.onPageDisappeared();
        }, 600);
    }

    onInPageAppeared() {
        var self = this;
        switch (this.currentInPage) {
            case this.INPAGE_ID_QUESTION:
                this.onWindowResized();
                // play the video from the start
                this.currentQuestionVideo.play();
                if (!this.replayingQuestion) {
                    this.currentQuestionVideo.addEventListener(
                        "ended",
                        function () {
                            self.onQuestionVideoFinished();
                        },
                        false
                    );
                }
                if (this.questionTimerInterval != null) {
                    clearInterval(this.questionTimerInterval);
                }
                this.questionTimerInterval = setInterval(function () {
                    self.onQuestionVideoTimeUpdate();
                }, 1000);
                //--- this.currentQuestionVideo.addEventListener( 'timeupdate', this.onQuestionVideoTimeUpdate );
                this.currentQuestionVideoPlaying = true;
                // reset the pause button
                this.questionShowingInstructions = true;
                this.questionTimer[0].innerHTML =
                    this.currentQuestion + " / " + this.QUESTIONS_QUANTITY;
                this.makeElementDisappear(this.playPauseButton);
                this.makeElementAppear(this.questionInstructions);
                this.makeElementAppear(this.questionBottomArea);
                this.makeElementDisappear(this.answerBottomArea);
                break;
            case this.INPAGE_ID_ANSWER:
                // focus on the input box
                this.currentQuestionAnswerInput.focus();
                this.makeElementDisappear(this.playPauseButton);
                this.questionTimer[0].innerHTML =
                    this.currentQuestion + " / " + this.QUESTIONS_QUANTITY;
                this.makeElementDisappear(this.questionBottomArea);
                this.makeElementAppear(this.answerBottomArea);
                break;
        }
    }

    setQuestionData() {
        var currentQuestionData = this.allQuestionsData[this.currentQuestion - 1];
        this.questionTimer[0].innerHTML =
            this.currentQuestion + " / " + this.QUESTIONS_QUANTITY;
        this.questionInstructions[0].innerHTML = currentQuestionData.instructions;
        this.soundSkillsContainer.find("#questionText")[0].innerHTML =
            currentQuestionData.question;
    }

    onInPageDisappeared() {
        // populate the question, if different
        if (this.nextInPage == this.INPAGE_ID_QUESTION) {
            this.setQuestionData();
        }
        // now make it proper disappear
        this.soundSkillsContainer
            .find("#" + this.currentPage + " #" + this.currentInPage)
            .addClass("hiddenElement");
        // make current appear
        this.makeElementAppear(
            this.soundSkillsContainer.find(
                "#" + this.currentPage + " #" + this.nextInPage
            )
        );
        this.currentInPage = this.nextInPage;
        this.nextInPage = null;
        // and now that a new page appeared, start that page
        this.onInPageAppeared();
    }

    swapInPage(newInPage) {
        var self = this;
        this.nextInPage = newInPage;
        // make previous disappear
        this.soundSkillsContainer
            .find("#" + this.currentPage + " #" + this.currentInPage)
            .removeClass("showElement")
            .addClass("hideElement");
        setTimeout(function () {
            self.onInPageDisappeared();
        }, 600);
    }

    onQuestionVideoTimeUpdate() {
        // check if it's time to swap for the timer
        //+++++
        // and update the timer if needed
        if (this.currentQuestionVideo.currentTime > 5) {
            if (this.questionShowingInstructions) {
                // hide instructions, and show play/pause button
                this.makeElementDisappear(
                    this.soundSkillsContainer.find("#questionInstructions")
                );
                this.makeElementAppear(this.playPauseButton);
                this.questionShowingInstructions = false;
            }
            var sec = Math.floor(
                this.currentQuestionVideo.duration -
                this.currentQuestionVideo.currentTime
            );
            this.questionTimer[0].innerHTML = "00:" + (sec < 10 ? "0" : "") + sec;
            if (sec == 0) {
                // no need for this interval anymore
                if (this.questionTimerInterval != null) {
                    clearInterval(this.questionTimerInterval);
                    this.questionTimerInterval = null;
                }
            }
        }
    }

    onQuestionVideoFinished() {
        if (this.questionTimerInterval != null) {
            clearInterval(this.questionTimerInterval);
            this.questionTimerInterval = null;
        }
        this.swapInPage(this.INPAGE_ID_ANSWER);
    }

    onStartClicked(e) {
        e.preventDefault();
        this.swapPage(this.PAGE_ID_LOADING);
        this.analytics.track("Soundskills", "Start");
    }

    onReadyClicked(e) {
        e.preventDefault();
        this.readyButton.unbind("click");
        this.swapPage(this.PAGE_ID_QUESTIONS);
        this.analytics.track("Soundskills", "Ready");
        this.analytics.trackFb("QuizStart", {});
    }

    onTogglePlayClicked(e) {
        e.preventDefault();
        // pause or resume depending on the current state
        if (this.currentQuestionVideoPlaying) {
            this.currentQuestionVideo.pause();
            this.playPauseButton[0].innerHTML = "Play";
        } else {
            this.currentQuestionVideo.play();
            this.playPauseButton[0].innerHTML = "Pause";
        }
        this.currentQuestionVideoPlaying = !this.currentQuestionVideoPlaying;
    }

    onReplayQuestionClicked(e) {
        e.preventDefault();
        this.replaysCount++;
        if (!this.currentQuestionReplayed) {
            this.currentQuestionReplayed = true;
            this.individualReplaysCount++;
        }
        this.replayingQuestion = true;
        this.swapInPage(this.INPAGE_ID_QUESTION);
        this.analytics.track(
            "Soundskills",
            "Replay",
            "Question " + this.currentQuestion
        );
    }
	/*
	loadNextQuestion() {
    
	}
	*/

    onNextQuestionClicked(e) {
        e.preventDefault();
        // check the answer, all in lowercase and removing spaces
        var givenAnswer = this.currentQuestionAnswerInput.value
            .toLowerCase()
            .replace(/ /g, "");
        // set up the result image to be right or wrong, and count the correct answers
        if (
            $.inArray(
                givenAnswer,
                this.allQuestionsData[this.currentQuestion - 1].answers
            ) != -1
        ) {
            this.correctAnswers++;
            this.soundSkillsContainer.find(
                "#resultBox" + this.currentQuestion
            )[0].src =
                "/assets/img/roles/linguist/sound-skills/answer-right.jpg";
            this.soundSkillsContainer.find(
                "#resultBoxHidden" + this.currentQuestion
            )[0].src =
                "/assets/img/roles/linguist/sound-skills/answer-right.jpg";
            this.analytics.track(
                "Soundskills",
                "Answer",
                "Question " + this.currentQuestion + " Correct"
            );
        } else {
            this.soundSkillsContainer.find(
                "#resultBox" + this.currentQuestion
            )[0].src =
                "/assets/img/roles/linguist/sound-skills/answer-wrong.jpg";
            this.soundSkillsContainer.find(
                "#resultBoxHidden" + this.currentQuestion
            )[0].src =
                "/assets/img/roles/linguist/sound-skills/answer-wrong.jpg";
            this.analytics.track(
                "Soundskills",
                "Answer",
                "Question " + this.currentQuestion + " Incorrect"
            );
        }
        this.currentQuestionAnswerInput.value = "";
        // disable continue button
        this.onAnswerInputChanged();
        // go to the next question, or the end result
        if (this.currentQuestion < this.QUESTIONS_QUANTITY) {
            this.currentQuestion++;
            this.currentQuestionReplayed = false;
            /* currentQuestionData.video */
            // check if we are replaying
            this.currentQuestionVideoContainer = this.soundSkillsContainer.find(
                "#questionVideo" + this.currentQuestion
            );
            this.currentQuestionVideo = this.currentQuestionVideoContainer[0];
            if (!this.currentQuestionVideoContainer.hasClass("videoVisible")) {
                // we are not replaying, we need to hide the previous one and show this one
                var previousVideoId = this.currentQuestion - 1;
                if (previousVideoId < 0) {
                    previousVideoId = 5;
                }
                this.soundSkillsContainer
                    .find("#questionVideo" + previousVideoId)
                    .removeClass("videoVisible")
                    .addClass("videoInvisible");
                this.currentQuestionVideoContainer
                    .removeClass("videoVisible")
                    .addClass("videoInvisible");
                this.currentQuestionVideoContainer
                    .removeClass("videoInvisible")
                    .addClass("videoVisible");
            }
            //++++++++
            // make current video appear
            //++++++++
            //++++++ <video id="questionVideo" src="/vid/q-music.mp4" playsinline webkit-playsinline></video>
            this.replayingQuestion = false;
            this.swapInPage(this.INPAGE_ID_QUESTION);
        } else {
            // populate the results page
            this.percResult = Math.min(
                100,
                Math.max(
                    0,
                    this.correctAnswers * this.SCORE_PER_CORRECT -
                    this.individualReplaysCount * this.SCORE_PER_REPLAY
                )
            );
            this.soundSkillsContainer.find("#resultPerc")[0].innerHTML =
                this.percResult + "%";
            var questionDesc = "question";
            if (
                this.individualReplaysCount === 0 ||
                this.individualReplaysCount > 1
            ) {
                questionDesc = "questions";
            }
            this.soundSkillsContainer.find("#resultReplays")[0].innerHTML =
                this.individualReplaysCount + " " + questionDesc + " replayed";
            // this.correctAnswers + " correct, " + this.replaysCount + " replay" + ( ( this.replaysCount != 1 )?"s":"" );
            var resultText = "";
            var resultTextContainer = this.soundSkillsContainer.find(
                "#resultText"
            )[0];
            if (this.percResult < 20) {
                resultText =
                    "You scored low, but you can still aim high. With a little work, you could be trained as an RAF Intelligence Analyst (Linguist) – analysing foreign language communications and providing intelligence.";
                this.tweetOutcome =
                    "With a little work, I could be trained in intelligence analysis. ";
            } else if (this.percResult < 50) {
                resultText =
                    "It’s a tough test, but you showed promise. That means you could be trained as an RAF Intelligence Analyst (Linguist) -analysing foreign language communications and providing intelligence.";
                this.tweetOutcome =
                    "With a little work, I could be trained in intelligence analysis. ";
            } else if (this.percResult <= 75) {
                resultText =
                    "You have a great skill for languages. That means you could be trained as an RAF Intelligence Analyst (Linguist) - analysing foreign language communications and providing intelligence.";
                this.tweetOutcome =
                    "I’ve got the talent to work in intelligence analysis. ";
            } else {
                resultText =
                    "You have a natural talent for languages. That means you could be trained as an RAF Intelligence Analyst (Linguist) - analysing foreign language communications and providing intelligence.";
                this.tweetOutcome =
                    "I have a natural talent for intelligence analysis. ";
            }

            resultTextContainer.innerHTML = resultText;
            this.swapPage(this.PAGE_ID_RESULTS);
            this.analytics.track(
                "Soundskills",
                "Complete",
                "Score " + this.percResult
            );
            this.analytics.trackFb("QuizComplete", {});
        }
    }

    onAnswerInputChanged() {
        var self = this;
        if (this.currentQuestionAnswerInput.value.replace(/ /g, "").length > 0) {
            // enable the button, if not enabled already
            if (
                this.soundSkillsContainer
                    .find("#nextQuestionButton")
                    .hasClass("standardButtonDisabled")
            ) {
                this.soundSkillsContainer
                    .find("#nextQuestionButton")
                    .bind("click", function (e) {
                        self.onNextQuestionClicked(e);
                    });
                this.soundSkillsContainer
                    .find("#nextQuestionButton")
                    .removeClass("standardButtonDisabled");
            }
        } else {
            // disable the button
            this.soundSkillsContainer.find("#nextQuestionButton").unbind("click");
            this.soundSkillsContainer
                .find("#nextQuestionButton")
                .addClass("standardButtonDisabled");
        }
    }

    onRestartQuizClicked(e) {
        e.preventDefault();
        // reset the intro
        this.resetIntro();
        // this.reset();
        this.swapPage(this.PAGE_ID_LOADING);
    }

    onShareFacebookClicked(e) {
        e.preventDefault();
        var shareURL = this.sharePageURL + this.percResult;
        this.sharePage("facebook", "", shareURL, "", "");
        this.analytics.track("Soundskills", "Share", "Facebook");
    }

    onShareTwitterClicked(e) {
        e.preventDefault();
        var shareURL = this.sharePageURL + this.percResult;
        this.sharePage("twitter", this.tweetOutcome, shareURL, "", "");
        this.analytics.track("Soundskills", "Share", "Twitter");
    }

    sharePage( whereToShare, shareMessage, urlToShareOverwrite, extraParams, titleToShare ) {
        var mustCloseWindow = false;
        var curUrl = window.location.href.replace(window.location.hash,'');
        if( extraParams == null ) { extraParams = ""; }
        if( titleToShare == null ) { titleToShare = ""; }
        var URLToShare,
          shareString = "";
        shareMessage = encodeURIComponent( shareMessage + " " );
        if( urlToShareOverwrite == null ) {
          URLToShare = encodeURIComponent( curUrl.split('?')[0] + extraParams );
        } else {
          URLToShare = encodeURIComponent( urlToShareOverwrite + extraParams );
        }
      
        switch( whereToShare ) {
          case "facebook" :
            shareString = "https://www.facebook.com/sharer/sharer.php?u=" + URLToShare;
            break;
          case "twitter" :
            shareString = "https://twitter.com/share?text=" + shareMessage + "&url=" + URLToShare;
            break;
          case "whatsapp" :
            shareString = "whatsapp://send?text=" + shareMessage + " " + URLToShare;
            break;
          case "email" :
            shareString = "mailto:?subject=" + encodeURIComponent( titleToShare ) + "&body=" + shareMessage + URLToShare;
            // mustCloseWindow = closeEMailWindow;
            break;
        }
        if( mustCloseWindow ){
         window.location.href = shareString;
        } else {
          var tempWindow = window.open( shareString, '_blank', 'width=555,height=330' );
        }
      }

    onAnswersShown() {
        // now make it proper disappear
        this.soundSkillsContainer
            .find("#hiddenAnswersContainer")
            .addClass("hiddenElement");
        this.soundSkillsContainer
            .find("#resultTextContainer")
            .addClass("hiddenElement");
        // make current appear
        this.makeElementAppear(
            this.soundSkillsContainer.find("#shownAnswersContainer")
        );
    }

    showAnswers(e) {
        e.preventDefault();
        this.makeElementDisappear(
            this.soundSkillsContainer.find("#hiddenAnswersContainer")
        );
        this.makeElementDisappear(
            this.soundSkillsContainer.find("#resultTextContainer")
        );
        var self = this;
        setTimeout(function () {
            self.onAnswersShown();
        }, 600);
        this.analytics.track("Soundskills", "Show Answers");
    }

    onAnswersHidden() {
        this.soundSkillsContainer
            .find("#shownAnswersContainer")
            .addClass("hiddenElement");
        this.makeElementAppear(
            this.soundSkillsContainer.find("#hiddenAnswersContainer")
        );
        this.makeElementAppear(
            this.soundSkillsContainer.find("#resultTextContainer")
        );
    }

    hideAnswers(e) {
        e.preventDefault();
        this.makeElementDisappear(
            this.soundSkillsContainer.find("#shownAnswersContainer")
        );
        var self = this;
        setTimeout(function () {
            self.onAnswersHidden();
        }, 600);
        this.analytics.track("Soundskills", "Hide Answers");
    }
}
