import '../lib/owl.carousel2';

export default class {

    constructor() {
        $('.carousel30-slides').owlCarousel({
            dots: false,
            nav: true,            
            navText: ['<div><img src="/assets/img/left-arrow-blue.svg" alt="blue left arrow large icon" /></div>', '<div><img src="/assets/img/right-arrow-blue.svg" alt="blue right arrow large icon" /></div>'],
            responsive: {
                0:{
                    items: 1
                },
                1024:{
                    items: 2
                },
                1440:{
                    items: 3
                }
            }
        });
        
        this.moveButton();
    }

    moveButton() {
        var button = $('.carousel30-bottombuttonwrapper').find('a');
        if (button.length > 0) {            
            var owlPrev = $('.carousel30-slides').find('.owl-prev');
            if (owlPrev.length != undefined) {
                button.insertAfter(owlPrev);
            }
        }
    }
}
