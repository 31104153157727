export default class {
    constructor() {
        
        this.addMethods();

        this.validationSettings = {
            groups: {
                dateOfBirth: "dobDay dobMonth dobYear"
            },
            rules: {
                dobDay: {
                    required: true,
                    minlength: 2,
                    maxlength:2,
                    digits: true,
                    datemultiple: ["#dobDay", "#dobMonth", "#dobYear"]
                },
                dobMonth: {
                    required: true,
                    minlength: 1,
                    maxlength:2,
                    digits: true,
                    datemultiple: ["#dobDay", "#dobMonth", "#dobYear"]
                },
                dobYear: {
                    required: true,
                    minlength: 4,
                    maxlength:4,
                    digits: true,
                    datemultiple: ["#dobDay", "#dobMonth", "#dobYear"]
                },
                dob: {
                    required: true,
                    minlength: 10,
                    maxlength:10,
                    checkAge: true
                },
                fullName: {
                    required: true,
                    minlength: 1,
                    checkFullName: true
                }
            },
            errorPlacement: (error, element, fnUnobtrusive, form) => {
                switch (element.attr("name")) {
                    case "dobDay":
                    case "dobMonth":
                    case "dobYear":
                        this.onGroupError(error, "DateMultiple", form);
                        break;
                    case 'dob':
                        this.onGroupError(error, "DateValidation", form);
                        break;
                    case 'fullName':
                        this.onGroupError(error, "FullNameValidation", form);
                        break;
                    default:
                        fnUnobtrusive(error, element);
                        break;
                }
            }
        }
    }

    extendValidation(element) {
        element.on('submit', (e) => {
            let $form = e.currentTarget;
            let validator = $($form).data("validator");

            this.extendValidationSettings($form, validator);
            setTimeout(() => {
                $($form).validate().form();
            }, 300);
        });
        element.on('change', (e) => {
            let $form = e.currentTarget;
            let validator = $($form).data("validator");
            this.extendValidationSettings($form, validator);            
        });
    }

    extendValidationSettings($form, validator) {
        if (validator.settings && !validator.settings.submitHandler) {
            $.extend(true, validator.settings.rules, this.validationSettings.rules);
            $.extend(true, validator.settings.groups, this.validationSettings.groups);
            this.initGroups(validator);
    
            let fnErrorReplacement = validator.settings.errorPlacement;

            validator.settings.errorPlacement = (error, element) => {
                this.validationSettings.errorPlacement(error, element, fnErrorReplacement, $form);
            }
            
            validator.settings.submitHandler = (form) => {
                
                this.sendButton = $('.registration-form-2017-send');
                this.sendButtonLoading = $('.registration-form-2017-send-loading');
                
                this.sendButton.hide();
                this.sendButtonLoading.addClass('show')

                form.submit();


            };
        }
    }
    
    onGroupError(error, inputElementName, form) {
        let container = $(form).find("[data-valmsg-for='" + inputElementName + "']");
        let replaceAttrValue = container.attr("data-valmsg-replace");
        let replace = replaceAttrValue ? $.parseJSON(replaceAttrValue) !== false : null;
        
        container.removeClass("field-validation-valid").addClass("field-validation-error");
        error.data("unobtrusiveContainer", container);
        
        if (replace) {
            container.empty();
            error.appendTo(container);
        }
        else {
            error.hide();
        }
    }

    initGroups(validators) {
        validators.groups = {};
        $.each(validators.settings.groups, (key, value) => {
            if (typeof value === "string") {
                value = value.split(/\s/);
            }
            $.each(value, (index, name) => {
                validators.groups[name] = key;
            });
        });
    }

    addMethods() {
        // and an unobtrusive adapter
        $.validator.unobtrusive.adapters.add('datemultiple', ['properties'], (options) => {
            options.rules['grouprequired'] = options.params;
            options.messages['grouprequired'] = options.message;
        });
        
        $.validator.addMethod('grouprequired', function (value, element, params) {
            var inputs = params.properties.split(',');
            var values = $.map(inputs, function (input, index) {
                var val = $('#' + input).val();
                return val != '' ? input : null;
            });
            return values.length == inputs.length;
        });

        /* Custom validation method to validate a date based on several fields: */
        $.validator.addMethod("datemultiple", function(value, element, params) {
            let daySelector = params[0];
            let monthSelector = params[1];
            let yearSelector = params[2];
            let day = parseInt($(daySelector).val(), 10);
            let month = parseInt($(monthSelector).val(), 10);
            let year = parseInt($(yearSelector).val(), 10);
            let dateEntered = new Date(year, month - 1, day);
            let today = new Date();

            var age = today.getFullYear() - dateEntered.getFullYear();
            var m = today.getMonth() - dateEntered.getMonth();
            var d = today.getDate() - dateEntered.getDate();
            if(d < 0){
                m--;
                d += 31;
            }
            if (m < 0 || (m === 0 && today.getDate() < dateEntered.getDate())) {
                age--;
                m += 12;
            }
            age += m / 12;
            var ret = this.optional(element) || (!isNaN(dateEntered.valueOf()) && (age >= 15.75 && age < 56) && (day <= 31 && month <= 12 && year >= today.getFullYear() - 57));
            return ret;

        }, "Please enter a valid date of birth<br><em>Please note you must be at least 15 years and 9 months to apply" );

        $.validator.addMethod("dateFormat",(value, element) => {
                return value.match(/^dd?-dd?-dddd$/);
        }, "Please enter a date in the format dd-mm-yyyy.");

        $.validator.addMethod("checkAge", function(value, element) {
            let date = value.split('-');
            let daySelector = date[0];
            let monthSelector = date[1];
            let yearSelector = date[2];
            let day = parseInt(daySelector, 10);
            let month = parseInt(monthSelector, 10);
            let year = parseInt(yearSelector, 10);
            let dateEntered = new Date(year, month - 1, day);
            let today = new Date();

            var age = today.getFullYear() - dateEntered.getFullYear();
            var m = today.getMonth() - dateEntered.getMonth();
            var d = today.getDate() - dateEntered.getDate();
            if(d < 0){
                m--;
                d += 31;
            }
            if (m < 0 || (m === 0 && today.getDate() < dateEntered.getDate())) {
                age--;
                m += 12;
            }
            age += m / 12;
            var ret = this.optional(element) || (!isNaN(dateEntered.valueOf()) && (age >= 15.75 && age < 56) && (day <= 31 && month <= 12 && year >= today.getFullYear() - 57));

            if(!ret) {
                $('.under-16').show();
            }
            else {
                $('.under-16').hide();
            }

            return ret;

        }, "Please enter a valid date of birth<br><em>Please note you must be at least 15 years and 9 months to apply" );

        $.validator.addMethod("checkFullName", function(value, element) {
            var ret = false;

            if(value !== undefined && value.length > 0) {
                let name = value.split(' ');

                if(name.length >= 1) {
                    ret = true;
                }
            }

            return ret;

        }, "Please enter your full name" );

    }
}