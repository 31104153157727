
export default class {

    constructor() {
        this.clickEvents();
    }

    clickEvents() {
        $(".custom-scroll-to-20").click((e) => {
            e.preventDefault();
            const $target = $(e.currentTarget);
            var el = $target.attr('data-el');
            var el_offset_top = ($(el).offset().top);
            //$('html,body').stop().animate({scrollTop:el_offset_top -78}, 250);          
            $('html,body').stop().animate({scrollTop:el_offset_top}, 500);          
        });
    }
}