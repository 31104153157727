import '../lib/owl.carousel2';

/*
NOTE: We have implemented the temporary fix in here:
https://github.com/OwlCarousel2/OwlCarousel2/issues/1771
If we lose that change we may need to remove the mouse drag (mouseDrag: false)
*/

export default class {

    constructor() {
       
        var self = this;

        this.desktopBreakpoint = 1026;     
        this.blockWrapper = $('.training-slides');

        this.blockWrapper.each(function() {
            $(this).on('initialized.owl.carousel', function() { 
                var container = $(this).closest('.training-container');
                self.enableDisableNavButtons(container);
            });
            $(this).owlCarousel({
                dots: false,
                nav: false,
                autoWidth: true,
                onTranslated: function(me) {
                    var container = $(me.target).closest('.training-container');
                    self.enableDisableNavButtons(container);
                }
            });
        });
                
        this.setClickEvents();
        this.setScrollEvent();
        
        $(window).on('resize', function(){
            self.reIntialise();
        });        
    }

    setClickEvents() {
        var self = this;

        $('.training-slide-wrapper').on('click', function(e) {       
            self.slideWrapperClick($(this));
        });
        
        $('.owl-prev-custom').on('click', function() {
            self.prev($(this));
        });

        $('.owl-next-custom').on('click', function() {
            self.next($(this));
        });
    }

    setScrollEvent() {
        var self = this;
        if (self.cardsInView()) {
            $('.training-slide-wrapper').addClass('hover');
        }
        $(window).on('resize scroll', function() {
            if (self.cardsInView()) {
                $('.training-slide-wrapper').addClass('hover');
            }
        });        
    }

    cardsInView() {

        var win = $(window);
        var viewport = {
            top: win.scrollTop(),
            left: win.scrollLeft()
        };
        viewport.right = viewport.left + win.width();
        viewport.bottom = viewport.top + win.height();

        var bounds = this.blockWrapper.offset();
        bounds.right = bounds.left + this.blockWrapper.outerWidth();
        bounds.bottom = bounds.top + this.blockWrapper.outerHeight();

        return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
    }
   
    slideWrapperClick(slideWrapper) {
        
        var container = slideWrapper.closest('.training-container');
        var navBlock = container.find('.owl-nav-custom');
        var slide = slideWrapper.closest('.training-slide'); 
        var slideWrapper = slide.find('.training-slide-wrapper');

        navBlock.find('.training-slide-moredetails-wrapper').remove();           
        container.find('.training-slide-downmarker').hide();    

        if (slideWrapper.hasClass('open')) {
            slideWrapper.removeClass('open');
            this.openSlideId = 0;
            this.openSlide = slide;
            navBlock.find('.owl-nav-custom-buttonwrapper').removeClass('pull-right');
            this.enableDisableNavButtons(container);
        } else {
            container.find('.training-slide-wrapper').removeClass('open');
            slideWrapper.addClass('open');
            this.showHideReadmore(slide);            
        }  
    }

    showHideReadmore(slide) {
        var downmarker = slide.find('.training-slide-downmarker');          
        var container = slide.closest('.training-container');        
        var navBlock = container.find('.owl-nav-custom');
        var readMoreBlock = navBlock.find('.training-slide-moredetails-wrapper');

        this.openSlideId = slide.data('slideid');
        this.openSlide = slide;

        container.find('.training-slide-downmarker').hide();  

        if (readMoreBlock.length > 1) {
            navBlock.find('.training-slide-moredetails-wrapper').animate({'margin-left': -5000, 'margin-right': 5000, 'width': 0, 'height': 0}, 300);
            navBlock.find('.owl-nav-custom-buttonwrapper').removeClass('pull-right');
        } else {
            navBlock.find('.training-slide-moredetails-wrapper').remove();
            navBlock.prepend((slide.find('.training-slide-moredetails').html()));
            navBlock.append((slide.find('.training-slide-moredetails').html()));
            navBlock.find('.owl-nav-custom-buttonwrapper').addClass('pull-right');
            navBlock.find('.training-slide-moredetails-wrapper').animate({'margin-left': 0, 'margin-right': 0}, 600);
            downmarker.show();
        }

        this.enableDisableNavButtons(container);
    }

    prev(button) {
        if ($(this).hasClass('disabled'))
            return;
        if (window.innerWidth < this.desktopBreakpoint) { 
            this.prevMobile(button);
        } else {
            this.prevDesktop(button);
        }        
    }

    next(button) {
        if ($(this).hasClass('disabled'))
            return;
        if (window.innerWidth < this.desktopBreakpoint) { 
            this.nextMobile(button);
        } else {
            this.nextDesktop(button);
        }          
    }

    prevDesktop(button) {
        var container = button.closest('.training-container');
        var openSlideId = button.closest('.owl-nav-custom').find('.training-slide-moredetails-wrapper').data('slideid');

        container.find('.training-slides').trigger('prev.owl.carousel');

        if (openSlideId > 1) {
            var prevSlideId = openSlideId - 1;
            var prevSlide = container.find('.training-slide[data-slideid="' + prevSlideId + '"]');
            var slideWrapper = prevSlide.find('.training-slide-wrapper');      
            this.slideWrapperClick($(slideWrapper));     
        } else {
            this.enableDisableNavButtons(container);
        }
    }

    prevMobile(button) {
        var container = button.closest('.training-container');
        var openSlideId = button.closest('.owl-nav-custom').find('.training-slide-moredetails-wrapper').data('slideid');

        container.find('.training-slides').trigger('prev.owl.carousel');

        if (openSlideId > 1) {
            var prevSlideId = openSlideId - 1;
            var prevSlide = container.find('.training-slide[data-slideid="' + prevSlideId + '"]');
            var slideWrapper = prevSlide.find('.training-slide-wrapper');      
            var slide = slideWrapper.closest('.training-slide');  
            var readMoreButton = slide.find('.training-slide-readmore');
            this.slideWrapperClick($(slideWrapper));
            this.readMoreClickMobile(readMoreButton)
        } 

        this.enableDisableNavButtons(container);
    }

    nextDesktop(button) {
        var container = button.closest('.training-container');
        var totalSlides = container.data('totalslides'); 
        var openSlideId = button.closest('.owl-nav-custom').find('.training-slide-moredetails-wrapper').data('slideid');
        
        container.find('.training-slides').trigger('next.owl.carousel');

        if (openSlideId != undefined && openSlideId < totalSlides) {                               
            var nextSlideId = openSlideId + 1;
            var nextSlide = container.find('.training-slide[data-slideid="' + nextSlideId + '"]');
            var slideWrapper = nextSlide.find('.training-slide-wrapper');      
            this.slideWrapperClick($(slideWrapper));     
        } else {
            this.enableDisableNavButtons(container);
        }
    }

    nextMobile(button) {
        var container = button.closest('.training-container');
        var totalSlides = container.data('totalslides'); 
        var openSlideId = button.closest('.owl-nav-custom').find('.training-slide-moredetails-wrapper').data('slideid');
        
        container.find('.training-slides').trigger('next.owl.carousel');
        
        if (openSlideId != undefined && openSlideId < totalSlides) {                               
            var nextSlideId = openSlideId + 1;
            var nextSlide = container.find('.training-slide[data-slideid="' + nextSlideId + '"]');
            var slideWrapper = nextSlide.find('.training-slide-wrapper');      
            var slide = slideWrapper.closest('.training-slide');            
            var readMoreButton = slide.find('.training-slide-readmore');
            this.slideWrapperClick($(slideWrapper));
            this.readMoreClickMobile(readMoreButton)
        } 

        this.enableDisableNavButtons(container);
    }

    enableDisableNavButtons(container) {        
        var cardCount = container.data('totalslides');
        var openCard = container.find('.owl-nav-custom').find('.training-slide-moredetails-wrapper').data('slideid');
        var prevButton = container.find('.owl-prev-custom');
        var nextButton = container.find('.owl-next-custom');

        prevButton.removeClass('disabled');  
        nextButton.removeClass('disabled'); 

        if (openCard != undefined) {
            if (openCard == 1) {
                prevButton.addClass('disabled');                        
            } else if (openCard == cardCount) {
                nextButton.addClass('disabled'); 
            }
        } else {                
            var containerWidth = container.width();     
            var owlStage =  container.find('.owl-stage');
            var owlWidth = parseFloat(owlStage.css('width'), 10);
            var owlStageTransform = owlStage.css('transform');
            var translateX = owlStageTransform == 'none' || owlStageTransform == undefined ? 0 :  -1 * owlStage.css('transform').split(/[()]/)[1].split(',')[4];
            
            if (translateX == 0)
                prevButton.addClass('disabled');

            if (owlWidth - translateX <= containerWidth)
                nextButton.addClass('disabled');
        }
    }

    reIntialise() {
        var self = this;
        $('.training-container').each(function() {
            $(this).find('.open').removeClass('open');
            $(this).find('.training-slide-downmarker').hide();
            $(this).find('.owl-nav-custom-buttonwrapper').removeClass('pull-right');
            $(this).find('.owl-nav-custom').find('.training-slide-moredetails-wrapper').remove();
            self.enableDisableNavButtons($(this));
        });
    }
}
