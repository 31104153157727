import FormValidator from '../helpers/forms-validator';
import Analytics from '../helpers/analytics';
import Utilities from '../helpers/utilities';
import 'jquery-mask-plugin';

import applyprogress from './applyprogress';

export default class {

    constructor() {
        this.firstLoad = true;
        this._formValidator = new FormValidator();
        this.analytics = new Analytics();
        this.utilities = new Utilities();

        if (this.utilities.smartLoad('.registration-form-2018')) {
            this.registrationForm = $('.registration-form-2018');
            this.serviceCheckButton = $('.service-toggle');
            this.currentServiceCheckButton = $('.currently-toggle-2017');
            this.validateForm();
            this.registrationFormEvents();
            this.genderSelfDescribe();      
            this.dateComponentEntry();
            this.nextButtonClickEvent();
        }
    }

    dateComponentEntry() {        
        var self = this;
        $('.dobcomponent').on('focusout', function() {
            self.buildDob();
        });
        $('.gtm-apply-date-of-birth').on('keydown', function(e) {
            e.preventDefault();
            return false;
         });
    }

    buildDob() {
        $('.gtm-apply-date-of-birth').val($('#dobDay').val() + '-' + $('#dobMonth').val() + '-' + $('#dobYear').val());
    }

    validateForm() {
        this.registrationForm = $('.registration-form-2018');
        this._formValidator.extendValidation(this.registrationForm);
    }

    registrationFormEvents() {
        this.eligibilityCheck();        
        this.serviceCheck();
        this.formatMaskDate();
    }

    OnSuccess(){
        $('.registration-form-2017').hide();
        $('.registration-form-2017-message').show();
    }

    OnError() { }

    genderSelfDescribe() {

        // trigger events
        $("#GenderSelfDescribeArea").hide();
        $("#GenderSelfDescValidation").hide();

        $("#Gender").on('change', function() {            
            if (this.value == "PreferToSelfDescribe") {
                $("#GenderSelfDescribeArea").show();
            }
            else {
                $("#GenderSelfDescribeArea").find("input[type=text]").val("");
                $("#GenderSelfDescribeArea").hide();
                $("#GenderSelfDescValidation").hide();
            }
        });

        $("#genderSelfDesc").on('change', function() {
            if ($("#genderSelfDesc")[0].value == '') {
                $("#genderSelfDescValidation").show();
            }
            else
            {
                $("#genderSelfDescValidation").hide();                
            }
        });

        // page load
        if ($("#Gender")[0].value == "PreferToSelfDescribe") {
            $("#GenderSelfDescribeArea").show();

            if ($("#GenderSelfDesc")[0].value == '') {
                $("#GenderSelfDescValidation").show();
            }
        }

        $(".registration-form-2017-send").on('click', function() {
            if ($("#Gender")[0].value == "PreferToSelfDescribe" && $("#GenderSelfDesc")[0].value == '') {   
                $("#GenderSelfDescValidation").show();
                return false;
            } else {
                $("#GenderSelfDescValidation").hide();
            }
        })
    }

    formatMaskDate() {
        setTimeout(() => {
            $('#dob').mask('00-00-0000');
        }, 300);
    }

    gtmTrack() {
        this.applySelectRoleForm.on('submit', (e) => {
            /*const time = this.applySelectRoleForm.find('.time-checkbox').val();
            const groupId = this.interestArea.find(":selected").val();
            let $form = e.currentTarget;
            let validator = $($form).data("validator");

            if ((time !== undefined && time !== '' && time !== null) && (groupId !== undefined && groupId !== null && groupId !== '')) {       
                if (time === 'reserve') {
                    const label = 'Spare time reserve';
                    this.analytics.dataLayer('iproSpareTime', 'Choose your role', label, 'Form submit');
                }                
            }

            validator.settings.submitHandler = (form) => {
                form.submit();
            };

            setTimeout(() => {
                $($form).validate().form();
            }, 300);*/
        })
    }

    eligibilityCheck() {
        $('.alert-panel').hide();
        $('.content[data-eligibilitystep="1"').show();
        $('.content[data-eligibilitystep="2"').hide();
        $('.content[data-eligibilitystep="3"').hide();
        this.eligibilityCheck1();
        this.eligibilityCheck2();
        this.eligibilityCheck3();
    }

    eligibilityCheck1() {

        $('input[name="eligibleAge"]').on('change', function() {
            var selection = $('input[name="eligibleAge"]:checked');
            if (selection.val() == 'yes') {       
                $('.eligibility-age-stepbutton').text('Next');
                selection.closest('.content').find('.alert-panel').hide();
            }            
            if (selection.val() == 'no') {
                $('.eligibility-age-stepbutton').text('Contact us');
                selection.closest('.content').find('.alert-panel').show();
            }
            $('.eligibility-age-stepbutton').prop('disabled', false);
        });

        $('.eligibility-age-stepbutton').on('click', function(e) {
            e.preventDefault();
            var selection = $('input[name="eligibleAge"]:checked');
            if (selection.val() == 'yes') {
                $('.content[data-eligibilitystep="1"').hide();
                $('.content[data-eligibilitystep="2"').show();
            }            
            if (selection.val() == 'no') {
                window.location.href = "/contact";
            }
        });
    }

    eligibilityCheck2() {

        $('input[name="eligibleNationality"]').on('change', function() {
            var selection = $('input[name="eligibleNationality"]:checked');
            if (selection.val() == 'yes') {       
                $('.eligibility-nationality-stepbutton').text('Next');
                selection.closest('.content').find('.alert-panel').hide();
            }            
            if (selection.val() == 'no') {
                $('.eligibility-nationality-stepbutton').text('Contact us');
                selection.closest('.content').find('.alert-panel').show();
            }
            $('.eligibility-nationality-stepbutton').prop('disabled', false);
        });

        $('.eligibility-nationality-stepbutton').on('click', function(e) {
            e.preventDefault();
            var selection = $('input[name="eligibleNationality"]:checked');
            if (selection.val() == 'yes') {
                $('.content[data-eligibilitystep="2"').hide();
                $('.content[data-eligibilitystep="3"').show();
            }            
            if (selection.val() == 'no') {
                window.location.href = "/contact";
            }
        });

    }

    eligibilityCheck3() {

        $('input[name="eligibleQualifications"]').on('change', function() {
            var selection = $('input[name="eligibleQualifications"]:checked');
            if (selection.val() == 'yes') {       
                $('.eligibility-qualifications-stepbutton').text('Next');
                selection.closest('.content').find('.alert-panel').hide();
            }            
            if (selection.val() == 'no') {
                $('.eligibility-qualifications-stepbutton').text('Contact us');
                selection.closest('.content').find('.alert-panel').show();
            }
            $('.eligibility-qualifications-stepbutton').prop('disabled', false);
        });

        $('.eligibility-qualifications-stepbutton').on('click', function(e) {
            e.preventDefault();
            var selection = $('input[name="eligibleQualifications"]:checked');
            if (selection.val() == 'yes') {
                $('.content[data-eligibilitystep="3"').hide();
                $('.step-1').hide();
                $('#register-2017-step-2').show();
                
                // Update the progress bar (different module)...
                var progress = new applyprogress();
                progress.currentStage = 3;
                progress.setStage();

            }            
            if (selection.val() == 'no') {
                window.location.href = "/contact";
            }
        });

    }

    serviceCheck() {

        var self = this;

        this.serviceCheckButton.on('click', (e) => {
            
            e.preventDefault();
            
            const $el = $(e.currentTarget);
            const $rejoiners = $('.rejoiners-serving-container');
            const $currentlyServing = $('.currently-serving-container');
            const $nextButton = $('#nextButton');      
            
            $nextButton.prop('disabled', true);

            this.serviceCheckButton.removeClass('active');
            $el.addClass('active');

            switch($el.data('value')) {
                case "Currently":
                    $rejoiners.hide();
                    $currentlyServing.show();
                    $nextButton.text('Next');
                    self.currentlyServingButtonState();
                    break;
                case "Previously":
                    $currentlyServing.hide();       
                    $rejoiners.show();       
                    $nextButton.text('Rejoiners');             
                    $nextButton.prop('disabled', false);
                    break;
                case "Never":
                    $currentlyServing.hide();                    
                    $rejoiners.hide();
                    $nextButton.text('Next');
                    $nextButton.prop('disabled', false);
                    break;                
            }
                        
            this.analytics.track('Register Your Interest', 'Serving in Armed Forces', $el.val(), $el.val());
        });

        $('#Service').on('change', (e) => {
            self.currentlyServingButtonState();
        });

        $('#auth-confirm').on('change', (e) => {
            self.currentlyServingButtonState();
        });
    }

    currentlyServingButtonState() {
        if ($('#auth-confirm').prop('checked') && $('#Service').val() != "0") {
            $('#nextButton').prop('disabled', false);
        } else {
            $('#nextButton').prop('disabled', true);
        }
    }

    nextButtonClickEvent() {
        $('#nextButton').on('click', function(e) {
            e.preventDefault();
            if ($('#serving_previously').hasClass('.active')) {
                window.location.href = '/contact';
            } else {
                $('#register-2017-step-2').hide();
                $('#register-2017-step-3').show();
            }
        });
    }
}
