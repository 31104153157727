import '../lib/owl.carousel2';
import Analytics from '../helpers/analytics';

export default class {
    constructor() {
        this.analytics = new Analytics();
        this.resizeTimer;
        this.delay = 0;//needs to be zero otherwise owlcenter can trigger before the resize 

        this.owl = $('.owl-carousel');
        this.owlOptions = {
            loop: true,
            margin: 0,
            // /animateOut: 'fadeOut',
            lazyLoad: true,
            responsiveClass: false,
            center: false,
            dots: false,
            dotsEach: true,
            autoplay: false,
            navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
            thumbContainerClass: 'owl-thumbs-c',
            thumbItemClass: 'owl-thumb-item-c',
            thumbsPrerendered: false,
            thumbImage: false,
            autoWidth: false,
            autoHeight: false,
            stagePadding: 0,
            responsive:{
                0:{
                    items:1,
                    nav: true
                },
                600:{
                    items:1,
                    nav: true
                },
                1000:{
                    items:1,
                    nav: true
                }
            }
        };
        this.widthProc = 0.65;
        this.heightProc = 0;

        this.renderOwl();
        //this.renderSlick();
    }
   
    renderOwl() {
        this.owl.each((key, value) => {
            const $target = value;

            const op = this.getCarouselOptions($target);

            this.owlOptions.autoplay = op.autoplay == "True" ? true : false;
            this.owlOptions.items = op.itemsResMeddium !== "" ? op.itemsResMeddium : 1;
            this.owlOptions.responsive = {
                0:{
                    items: op.itemsResSmall !== "" ? op.itemsResSmall : 1,
                    nav: op.showNaw == "True" ? true : false
                },
                600:{
                    items: op.itemsResMeddium !== "" ? op.itemsResMeddium : 1,
                    nav: op.showNaw == "True" ? true : false
                },
                1000:{
                    items: op.itemsResLarge !== "" ? op.itemsResLarge : 1,
                    nav: op.showNaw == "True" ? true : false
                }
            }
            this.owlOptions.dots = op.dots == "True" ? true : false;
            this.owlOptions.center = op.center == "True" ? true : false;
            this.owlOptions.loop = op.loop == "True" ? true : false;
            this.owlOptions.autoWidth = op.autoWidth == "True" ? true : false;
            this.owlOptions.margin = op.margin !== "" ? op.margin : 0;
            this.owlOptions.thumbs = op.thumb == "True" ? true : false;
            this.owlOptions.responsiveClass = op.center == "True" ? false : true;
            this.owlOptions.autoHeight = op.autoHeight === "True" ? true : false;

            if(this.owlOptions.center && this.owlOptions.autoWidth) {
                this.owlOptions.mouseDrag = false;
                //this.owlOptions.touchDrag = false;
                this.owlOptions.pullDrag = false;
            }

            let owl = $($target).owlCarousel(this.owlOptions);
            
            if(this.owlOptions.center && this.owlOptions.autoWidth) {
                $('.owl-stage').css('transform', 'translate3d(30px, 0px, 0px)');          
                this.owlEvents(owl, $target, this.owlOptions.margin);
            }

            if($($target).hasClass("owl-15")) {                
                this.owlSlideNumerEvant(owl, $target);
            }

            if($($target).hasClass('owl-20') && !$($target).hasClass('owl-ast')) {
                this.olwEvents20(owl, $target);
            }

            if($($target).hasClass('owl-ast')) {
                this.olwEventsAst20(owl, $target);
            }
        });
    }

    owlSlideNumerEvant(owl, $target) {      

        owl.on('changed.owl.carousel', (event) => {
            this.resize($($target), 0);5
            if (!event.namespace) return;
            let carousel = event.relatedTarget;
            let currentIndex = carousel.relative(carousel.current()) + 1;
            if(currentIndex < 10) {
                currentIndex = ("0" + currentIndex).slice(-2);   
            }
           $('.carousel-counter .current').html(currentIndex);
        });

        $(window).on('resize', _.debounce((e) => {
            this.resize($($target), 0);
        }, this.delay)).resize();

        owl.on('refreshed.owl.carousel', (event) => {
            this.resize($($target), 0);       
        });
        owl.on('onResize.owl.carousel', (event) => {
            this.resize($($target), 0);                 
        });
    }

    olwEvents20(owl, $target) {

        owl.on('changed.owl.carousel', (event) => {
            this.resize($($target), 0);
            const total = $($target).data('slides');
            if (!event.namespace) return;
            let carousel = event.relatedTarget;
            let currentIndex = carousel.relative(carousel.current()) + 1;
            const procentage = currentIndex === 1 ? 0 : (currentIndex / total) * 100;

            console.log(procentage);

            $('.progress-bar .bar').css('width', `${procentage}%`);
        });


        $(window).on('resize', _.debounce((e) => {
            this.resize($($target), 0);
        }, this.delay)).resize();

        owl.on('refreshed.owl.carousel', (event) => {
            this.resize($($target), 0);       
        });
        owl.on('onResize.owl.carousel', (event) => {
            this.resize($($target), 0);                 
        });
    }

    olwEventsAst20(owl, $target) {

        owl.on('changed.owl.carousel', (event) => {
            this.resize($($target), 0);
            const current = event.item.index;
            const $slide = $(event.target).find(".owl-item").eq(current).find(".table");
            const name = $slide.data('name');

            $(".results-slider-controller-label").text(name);
        });


        $(window).on('resize', _.debounce((e) => {
            this.resize($($target), 0);
        }, this.delay)).resize();

        owl.on('refreshed.owl.carousel', (event) => {
            this.resize($($target), 0);       
        });
        owl.on('onResize.owl.carousel', (event) => {
            this.resize($($target), 0);                 
        });

        //Next and Prev events
        $('.owl-ast-prev').on('click', (e) => {
            e.preventDefault();
            owl.trigger('prev.owl.carousel');
        });
        $('.owl-ast-next').on('click', (e) => {
            e.preventDefault();
            owl.trigger('next.owl.carousel');
        });
    }

    owlEvents(owl, $target, margin) {
        let $carousel = $($target);
        const windowWidth = $(window).width();
        //On Slide Click
        setTimeout(() => {
            const slide = $carousel.find('.slide');
            slide.on('click', (e) => {
                e.preventDefault();
                const $el = e.currentTarget;
                const index = $($el).data('index');
                $carousel.trigger('to.owl.carousel', index, 300)
            })
        }, 200);
           
        owl.on('changed.owl.carousel', (event) =>  {
            this.owlCenter(event, margin);
            const stage = `Stage ${event.item.index}`;
            this.analytics.track('Training', 'Carousel', stage, stage);
        });

        $(window).on('resize', _.debounce((e) => {
            this.resize($($target), margin);
            //console.log("window resize");
        }, this.delay)).resize();

        owl.on('refreshed.owl.carousel', (event) => {
            this.resize($($target), margin);
           this.owlCenter(event, margin, windowWidth);        
           //console.log("refreshed.owl.carousel");      
        });
        owl.on('onResize.owl.carousel', (event) => {
            this.resize($($target), margin);
            this.owlCenter(event, margin, windowWidth);              
            //console.log("onResize.owl.carousel");                     
        });
    }

/* not used
    startResize($el, margin) {       
        clearTimeout(this.resizeTimer);
        this.resizeTimer = setTimeout(this.resize($el, margin), this.delay);
    }*/

    owlCenter(event, margin, winWidth) {
        const element = event.target;           // DOM element, in this example .owl-carousel
        const name = event.type;                // Name of the event, in this example dragged
        const namespace = event.namespace;      // Namespace of the event, in this example owl.carousel
        const items = event.item.count;         // Number of items
        const item = event.item.index;          // Position of the current item
        const bodyWidth = $('main > .container').width();               
        const $stageWidth = $(element).find('.owl-stage').width();
        const windowWidth = winWidth !== $(window).width() ? $(window).width() : winWidth;

        const owlStage = $(element).find('.owl-stage');
        
        if (item === 0) {
            setTimeout(() => {
                $(owlStage).css('transform', 'translate3d(30px, 0px, 0px)');
            }, 10)
        }

        else if (item === items - 1) {

            /*let sliderWidth = $(element).find('.slide').width();
            let rightMargin = 10;
            if (bodyWidth < 744) {
                rightMargin = -35;
            }
            sliderWidth = (sliderWidth + (sliderWidth * 0.65)) - (margin * items) - rightMargin;
            let left = $stageWidth - sliderWidth - 40;*/

            //var new_left = ($stageWidth - bodyWidth) - ((10 * (items)) + 30 );
            var new_left = ($stageWidth - bodyWidth) - ((10 * (items)) + 8 );
            setTimeout(() => {
                $(owlStage).css('transform', `translate3d(-${new_left}px, 0px, 0px)`);
            }, 10)
            
            //console.log("item === items - 1 ");

        }
        else {
            let cellPadding = 40;
            if (windowWidth >= 1026) {
                cellPadding = 60;
            }

            let sliderWidthSingle = ($(element).find('.slide').width() + cellPadding);
            let sliderWidth = (sliderWidthSingle * (item));
            


            let nudgeToCenter = ((bodyWidth - (sliderWidthSingle)) / 2);
            let left = (sliderWidth + (item * (10))) - nudgeToCenter; //10 is the margin on th slide

            setTimeout(() => {
                $(owlStage).css('transform', `translate3d(-${left}px, 0px, 0px)`);
            }, 50)

            //console.log("bodyWidth: " + bodyWidth + "\n item: " + item + "\n sliderWithSingle: " + sliderWidthSingle + "\n left: " + left + "\n windowWidth: "+windowWidth);
        }

    }

    resize(carousel, margin) {
        let $stage = carousel.find('.owl-stage');
        let stageW = $stage.width();
        let $el = carousel.find('.owl-item');
        let elWidth = $el;
        let elW = 0;

        $el.each((key, value) => {
            let $slide = $(value).find('.slide');
            let bodyWidth = 0;

            let slideWidth = 0;

            if($slide !== undefined && $slide.length > 0) {
                bodyWidth = $('main > .container').width();
                slideWidth = this.recalcOwlWidth(bodyWidth);
            }
            else {
                $slide = $(value).find('.slide-15');
                bodyWidth = $('main > .container-wide').width();
                slideWidth = bodyWidth < 769 ? bodyWidth : bodyWidth * 0.5;
            }
            
            const slideHeight = this.recalcOwlHeight($slide.height(), $slide.width(), slideWidth, bodyWidth, margin);

            carousel.width(bodyWidth);

            if(carousel.find('.owl-dots').length > 0) {
                carousel.find('.owl-dots').width(bodyWidth);                
            }

            $slide.width(Math.ceil(slideWidth));            
            //$slide.height(Math.ceil(slideHeight)); //commented out for a css solution
            //console.log("reSIZE slide.height: " + $slide.height() + " | slideHeight: " + slideHeight);
            
            elW += $(value)[0].getBoundingClientRect().width + (margin * 2);
        });

        $stage.width(Math.ceil(elW));
        
        //this.equalsSlideHeight(carousel); //commented out for a css solution
    }

    recalcOwlWidth(bodyWidth) {
        return bodyWidth * this.widthProc; 
    }

    recalcOwlHeight(oldHeight, oldWidth, newWidth, bodyWidth, margin) {
        this.heightProc = oldWidth / newWidth;
        let mobileFix = 0;

        if(bodyWidth < 744) {
            mobileFix = 60;
        }
        
        var tempVar = (oldHeight * this.heightProc) - mobileFix + margin;
        //console.log("recalc " + tempVar);
        return tempVar;    
    }

    equalsSlideHeight(carousel) {
        let slideHeight = [];
        const $slide = carousel.find('.slide');
        $slide.each((key, value) => {
            const $el = $(value);        
            slideHeight.push($el.height());
        });

        const equalsHeight = slideHeight.sort((a, b) => {
            return b-a;
        })[0];
        carousel.find('.slide').height(Math.ceil(equalsHeight));     

        //console.log("EQsize " + equalsHeight);
    }

    getCarouselOptions($target) {
        return {
            autoplay: $($target).data('autoplay'),
            showNaw: $($target).data('show-nav'),
            itemsResSmall: $($target).data('items-res-s'),
            itemsResMeddium: $($target).data('items-res-m'),
            itemsResLarge: $($target).data('items-res-l'),
            dots: $($target).data('show-dots'),
            center: $($target).data('center'),
            autoWidth: $($target).data('auto-width'),
            thumb: $($target).data('show-thumbs'),
            margin: $($target).data('margin'),
            speed: $($target).data('speed'),
            vartical: $($target).data('vartical-layout'),
            loop: $($target).data('loop'),
            autoHeight: $($target).data('auto-height')
        }
       
    }
}
