import Analytics from '../helpers/analytics';

export default class {
    constructor() {
        this.trackingLink = $('.tracking-codes-click-event');

        this.analytics = new Analytics();

        this.trackClickEvent();
    }

    trackClickEvent() {
        this.trackingLink.on('click', (e) => {
            const $el = $(e.currentTarget);

            const category = $el.data('ga-category');
            const action = $el.data('ga-action');
            const label = $el.data('ga-label');
            let value = $el.data('ga-value');
            
            if(category !== undefined && action !== undefined && label !== undefined) {
                value = value !== undefined ? value : label;

                this.analytics.track(category, action, label, value);
            }
        });
    }
}