import './app'

document.addEventListener("DOMContentLoaded", () => {
  
    if (document.querySelector('[data-airops-game]') !== null) {
        window.createjs = window.AdobeAn = {};
        import('./airops').then(() => {
            GeneralFunctions.init();
        
            if(GeneralFunctions.isCanvasSupported()) {
                GeneralFunctions.initStageResize();
        
                if(!GeneralFunctions.mob) {
                    $('.mainContainer').show();
                    GameController.init();
                }
            }else{
                alert("Please use a more up to date browser.");
            }
        });
    }
  
    if (document.querySelector('.mission-twenty-twenty') !== null) {

        var missiondata = document.createElement('script');
        missiondata.src = '/mission2020/mission2020data.js';
        document.head.appendChild(missiondata);

        var missionscript = document.createElement('script');
        missionscript.src = '/mission2020/mission2020.js';
        document.head.appendChild(missionscript);
    }

});