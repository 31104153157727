
export default class {

    constructor() {
        this.clickEvents();
        this.showActive();
        var width = $(window).width();
        $(window).on('resize', function() {
            if ($(window).width() != width) {
                width = $(window).width();
                $('.search-wrapper').hide();
                $('.close-icon').hide();
                $('.mobile-menu').hide();
                $('.hamburger-icon').show();
            }
        });
        $(document).scroll(function () {
            var $nav = $('.header-wrapper');
            $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
          });
    }

    showActive() {
        let url = window.location.href;
        $('menu ul li a').each(function() {
            if (this.href === url) {
                $(this).closest('li').addClass('active');
            }
        });
    }

    clickEvents() {

        $('.hamburger-icon').on('click', function() {
            $('.hamburger-icon').hide();
            $('.close-icon').show();
            $('.mobile-menu').slideToggle(350);
            $('.header-wrapper').toggleClass('background');
        });

        $('.close-icon').on('click', function() {
            $('.close-icon').hide();
            $('.hamburger-icon').show();
            $('.mobile-menu').hide();
            $('.header-wrapper').removeClass('background');
            $('.search-wrapper').hide();
        });

        $('.search-close').on('click', function() {
            $('.search-wrapper').slideUp();
        });

        var webChatProvider =  $('#k2c-live-chat').data('webchatprovider');
        var webChatUrl = $('#k2c-live-chat').data('url');

        if (webChatProvider == "NiceContact") {
            var webChatId = parseInt($('#k2c-live-chat').data('webchatid'));
            var webChatKey = $('#k2c-live-chat').data('webchatkey');            
            $("#k2c-live-chat a").one('click', function() {
                loadNiceContact();
                var setIntervalID = setInterval(function() {
                    var chatShown = $('#be-frame');
                    if (chatShown.length > 0) {                   
                        clearInterval(setIntervalID);
                        brandembassy('openChatWindow');
                    }
                }, 200);                
            });
        } else {
            // On first click only as the script loaded takes over
            $("#k2c-live-chat a").one("click", function(event) {
                $.getScript(webChatUrl).done(function() {          
                    // Give script a chance to do its thing                    
                    /* setTimeout wasn't working. */
                    var setIntervalID = setInterval(function() {
                        var chatShown = $('#k2cWidgetPosition').hasClass('visible');
                        if (chatShown) {                
                            clearInterval(setIntervalID);
                        } else {
                            $(".header-chat-button").trigger("click");
                        }
                    }, 500); // retry every half second until the chat pops up            
                });
            });
        }
    
        $(".header-chat-button").on("click", function (event) { 
            event.preventDefault();            
            $(".header-20 #k2c-live-chat a").trigger("click");        
        }); 

        $(".header-chat-button").on("click", function (event) { 
            event.preventDefault();            
            $("#k2c-live-chat a").trigger("click");        
        });  


        $('.search-link').on('click', function(event) {

            event.preventDefault(); 

            //$('.search-wrapper').slideToggle(350);
            if ($('.search-wrapper').is(':visible')) {
                $('.search-wrapper').slideUp();                
            } else {
                $('.search-wrapper').slideDown(350);
                $('.close-icon').show();
                $('.hamburger-icon').hide();
                $('input[name="searchTerm"').trigger('focus');
            }

        });


    }
}
