import Utilities from '../../helpers/utilities';

export default class {

    constructor() {

        console.log('AST loading...');

        this.nextQestionButton = $('.next-qestion');
        this.playSequenceButton = $('.play-sequence');
        this.currentQuestion = $('.currentQestion');
        this._utilities = new Utilities();
        this.questionId = 'VR';
        this.elepsed_secods = 0;
        this.updateClockInterval = null;
        this.timeLeft = 0;
        this.timeSpend = 0;        

        this.questions = [
            {
                id: "VR",
                name: 'Verbal Reasoning',
                totalSubquestions: 12,
                timeLimit: 540,
                desiredTime: 270,
                userTimeLeft: 0,
                userTime: 0,
                userScore: 0,
                allQuestions: [
                    {
                        id: "VRQ1",
                        allSubquestions: [
                            { number: 1, id: "VRQ1-1", correctAnswer: "E", userAnswer: "" },
                            { number: 2, id: "VRQ1-2", correctAnswer: "B", userAnswer: "" },
                            { number: 3, id: "VRQ1-3", correctAnswer: "C", userAnswer: "" },
                        ],
                    },
                    {
                        id: "VRQ2",
                        allSubquestions: [
                            { number: 4, id: "VRQ2-1", correctAnswer: "B", userAnswer: "" },
                            { number: 5, id: "VRQ2-2", correctAnswer: "B", userAnswer: "" },
                            { number: 6, id: "VRQ2-3", correctAnswer: "D", userAnswer: "" },
                        ],
                    },
                    {
                        id: "VRQ3",
                        allSubquestions: [
                            { number: 7, id: "VRQ3-1", correctAnswer: "C", userAnswer: "" },
                            { number: 8, id: "VRQ3-2", correctAnswer: "D", userAnswer: "" },
                        ],
                    },
                    {
                        id: "VRQ4",
                        allSubquestions: [
                            { number: 9, id: "VRQ4-1", correctAnswer: "E", userAnswer: "" },
                            { number: 10, id: "VRQ4-2", correctAnswer: "C", userAnswer: "" },
                            { number: 11, id: "VRQ4-3", correctAnswer: "A", userAnswer: "" },
                            { number: 12, id: "VRQ4-4", correctAnswer: "E", userAnswer: "" },
                        ],
                    },
                ],
            },
            {
                id: "NR1",
                name: 'Numerical Reasoning Part 1',
                totalSubquestions: 5,
                timeLimit: 80,
                desiredTime: 40,
                userTimeLeft: 0,
                userTime: 0,
                userScore: 0,
                allQuestions: [
                    {
                        id: "NR1Q1",
                        allSubquestions: [
                            { number: 13, id: "NR1Q1-1", correctAnswer: "B", userAnswer: "" },
                            { number: 14, id: "NR1Q1-2", correctAnswer: "A", userAnswer: "" },
                            { number: 15, id: "NR1Q1-3", correctAnswer: "C", userAnswer: "" },
                            { number: 16, id: "NR1Q1-4", correctAnswer: "A", userAnswer: "" },
                            { number: 17, id: "NR1Q1-5", correctAnswer: "D", userAnswer: "" },
                        ],
                    }
                ],
            },
            {
                id: "NR2",
                name: 'Numerical Reasoning Part 2',
                totalSubquestions: 9,
                timeLimit: 395,
                desiredTime: 198,
                userTimeLeft: 0,
                userTime: 0,
                userScore: 0,
                allQuestions: [
                    {
                        id: "NR2Q1",
                        allSubquestions: [
                            { number: 18, id: "NR2Q1-1", correctAnswer: "C", userAnswer: "" },
                            { number: 19, id: "NR2Q1-2", correctAnswer: "A", userAnswer: "" },
                        ],
                    },
                    {
                        id: "NR2Q2",
                        allSubquestions: [
                            { number: 20, id: "NR2Q2-1", correctAnswer: "E", userAnswer: "" },
                            { number: 21, id: "NR2Q2-2", correctAnswer: "D", userAnswer: "" },
                        ],
                    },
                    {
                        id: "NR2Q3",
                        allSubquestions: [
                            { number: 22, id: "NR2Q3-1", correctAnswer: "C", userAnswer: "" },
                            { number: 23, id: "NR2Q3-2", correctAnswer: "D", userAnswer: "" },
                            { number: 24, id: "NR2Q3-3", correctAnswer: "C", userAnswer: "" },
                            { number: 25, id: "NR2Q3-4", correctAnswer: "B", userAnswer: "" },
                            { number: 26, id: "NR2Q3-5", correctAnswer: "A", userAnswer: "" },
                        ],
                    }
                ],
            },
            {
                id: "WR",
                name: 'Work Rate',
                totalSubquestions:4, 
                timeLimit:50, 
                desiredTime:25, 
                userTimeLeft:0, 
                userTime:0, 
                userScore:0,
                allQuestions: [
                    { 
                        id:'WRQ1',
                        allSubquestions:[
                            { number:27, id:'WRQ1-1', correctAnswer:'B', userAnswer:'' },
                            { number:28, id:'WRQ1-2', correctAnswer:'D', userAnswer:'' },
                            { number:29, id:'WRQ1-3', correctAnswer:'A', userAnswer:'' },
                            { number:30, id:'WRQ1-4', correctAnswer:'D', userAnswer:'' }
                        ]
                    }
                ],
            },
            {
                id: "SR1",
                name: 'Spatial Reasoning Part 1',
                totalSubquestions:2, 
                timeLimit:50, 
                desiredTime:25, 
                userTimeLeft:0, 
                userTime:0, 
                userScore:0,
                allQuestions: [
                    { 
                        id:'SR1Q1',
                        allSubquestions:[
                            { number:31, id:'SR1Q1-1', correctAnswer:'D', userAnswer:'' },
                            { number:32, id:'SR1Q1-2', correctAnswer:'D', userAnswer:'' }
                        ]
                    }
                ],
            },
            {
                id: "SR2",
                name: 'Spatial Reasoning Part 2',
                totalSubquestions:2, 
                timeLimit:35, 
                desiredTime:17, 
                userTimeLeft:0, 
                userTime:0,
                userScore:0,
                allQuestions: [
                    { 
                        id:'SR2Q1',
                        allSubquestions:[
                            { number:33, id:'SR2Q1-1', correctAnswer:'A', userAnswer:'' },
                            { number:34, id:'SR2Q1-2', correctAnswer:'C', userAnswer:'' }
                        ]
                    }
                ],
            },
            {
                id: "EC",
                name: 'Electrical Comprehension',
                totalSubquestions:4, 
                timeLimit:35, 
                desiredTime:17, 
                userTimeLeft:0, 
                userTime:0,
                userScore:0,
                allQuestions: [
                    { 
                        id:'ECQ1',
                        allSubquestions:[
                            { number:35, id:'ECQ1-1', correctAnswer:'D', userAnswer:'' },
                            { number:36, id:'ECQ1-2', correctAnswer:'C', userAnswer:'' },
                            { number:37, id:'ECQ1-3', correctAnswer:'B', userAnswer:'' },
                            { number:38, id:'ECQ1-4', correctAnswer:'C', userAnswer:'' }
                        ]
                    }
                ],
            },
            {
                id: "MC",
                name: 'Mechanical Comprehension',
                totalSubquestions:4, 
                timeLimit:165, 
                desiredTime:83, 
                userTimeLeft:0, 
                userTime:0, 
                userScore:0,
                allQuestions: [
                    { 
                        id:'MCQ1',
                        allSubquestions:[
                            { number:39, id:'MCQ1-1', correctAnswer:'C', userAnswer:'' },
                            { number:40, id:'MCQ1-2', correctAnswer:'A', userAnswer:'' },
                            { number:41, id:'MCQ1-3', correctAnswer:'B', userAnswer:'' },
                            { number:42, id:'MCQ1-4', correctAnswer:'E', userAnswer:'' }
                        ]
                    }
                ],
            },            
        ]


        this.sliderEvents();
        this.clickEvents();
        //no need for scroll events for now
        //this.scrollEvents();

        this.skipQusetion();

        this.startClock();

        
    }

    skipQusetion() {
        const qID = this._utilities.getUrlVars()['id'];

        if(qID !== undefined && qID !== null) {
            const $question = $(`#${qID}`);
            const id = $question.data('id');
    
            this.questionId = id;
    
            $('.airman-selection-test-20-page').hide();
    
            $question.show();
        }
    }

    clickEvents() {        
        this.nextQestionButton.on('click', (e) => {
            e.preventDefault();

            const $target = $(e.currentTarget);
            const id = $target.data('id');
            let next = $target.data('next');
            let current = $target.data('current');
            const answer = $(`input[name=${current}]:checked`).val();
            const last = $target.data('last') !== undefined ? true : false;
            const summary = $target.data('summary') !== undefined ? true : false;
            let hide = $target.data('hide');
            let question = $target.data('qestion-id');
            const os = $target.data('os') !== undefined ? true : false; //overlay summary

            if(id !== undefined) {
                this.questionId = id;
            }

            //set answer
            if(current !== undefined) {
                this.setAnswer(id, question, current, answer);
            }

            //precess to nex qestion
            next = $(`#${next}`);
            if(current !== undefined) {
                current = $(`#${current}`);
            }
            question =  $(`#${question}`);
            hide =  $(`#${hide}`);

            //Show sumary
            if(summary && !os) {
                this.showSummary(id, next);
            }

            //Show Overlay Summary
            if(os) {
                this.ovarlaySummary();
            }

            //Proces to next qestion / change qestion
            this.processAnswer(question, current, next, last, hide, answer);
        });


        this.playSequenceButton.on('click', (e) => {
            e.preventDefault();

            const $target = $(e.currentTarget);
            const sequence = $target.data('sequence');
            const question = $target.data('question');
            const $sequence = $(`#${sequence}`);
            const $question = $(`#${question}`);

            $target.hide();

            this.playSequence($sequence, $question);
        });

    }

    

    playSequence($sequence, $question) {

        const $frames = $sequence.children();
        const totalFrames = $frames.length;
        let timeOut = 1000;
        
        //show first one
        $frames.hide();
        $sequence.show();
        $($frames[0]).fadeIn(500);

        //loop next one
        $frames.each((index, value) => {
            const $el = $(value);
            const $next = $el.next();

            setTimeout(() => {

                $el.hide();
            
                if($next !== undefined) {
                    $next.fadeIn(500); 
                }

                if(index === (totalFrames - 1)) {
                    $sequence.hide();
                    $question.fadeIn(100);
                }
                
            }, timeOut * (index+1));
        }); 

    }
    

    startClock() {
        $('.time-left').text("0:00");

        const firstQuestion = this._utilities.findInArray(this.questions, this.questionId);
        this.timeLeft = firstQuestion.timeLimit;

        if(this.updateClockInterval == null) {
            this.updateClockInterval = setInterval(this.updateClock.bind(this), 1000)
        }
    }

    updateClock() {
        this.timeSpend = this.timeSpend + 1;

        if(this.timeLeft > 0 ) {
            this.timeLeft = this.timeLeft - 1;

            $('.time-left').text(this.get_elapsed_time_string(this.timeLeft));
        }
    }

    resetClock() {
        this.timeLeft = 0;
        this.timeSpend = 0;
        clearInterval(this.updateClockInterval);
        this.updateClockInterval = null;
    }

    processAnswer($question, $current, $next, last, $hide, answer) {
        //change question
        if(answer !== undefined) { 
            //show hide sub qestion
            if($current !== undefined) {
                $current.hide();
            }

            let offset = 120;
        
            if(last) {
                $hide.hide();
                offset = 200;               
            } else {
                //set qestion number
                const title = $next.data('title');
                $question.find('.qestion-number').text(title);
            }

            $next.fadeIn();            
            this._utilities.scrollTo($next, offset);
        }
        //jump from summary to qestion
        else if(answer === undefined && $current === undefined) { 
            $hide.hide();
            $next.fadeIn();
            this._utilities.scrollTo($next, 200);

            //change question index
            this.setCurrentQestion($next);
        }
    }

    setCurrentQestion($next) {
        const id = $next.data('id');
        const startClock = $next.data('clock') !== undefined ? true : false;
        this.questionId = id;

        let currentIndex = this._utilities.findIndex(this.questions, id) + 1;
        this.currentQuestion.text(this.pretty_number_string(currentIndex));
        
        //start new time
        if(startClock) {
            this.startClock();
        }
        else {
            $('.time-left').text("0:00");
        }
    }

    setAnswer(id, questionId, currentId, answer) {
        if(answer !== undefined) {
            const qestion = this._utilities.findInArray(this.questions, id)
            const current = this._utilities.findInArray(qestion.allQuestions, questionId)
            const subQuestion = this._utilities.findInArray(current.allSubquestions, currentId)
            subQuestion.userAnswer = answer;

            if(subQuestion.userAnswer === subQuestion.correctAnswer) {
                qestion.userScore++;
            }
        }  
    }

    showSummary(id, $next) {
        const qestion = this._utilities.findInArray(this.questions, id);
        const $userScore = $next.find('.userScore');
        const $tatalQestions = $next.find('.tatalQestions');

        //Update user score
        $userScore.text(this.pretty_number_string(qestion.userScore));
        
        //get total qestion count
        $tatalQestions.text(this.pretty_number_string(qestion.totalSubquestions));

        //update User time
        qestion.userTimeLeft = this.timeLeft;
        
        if(this.timeLeft == 0 && this.timeSpend > qestion.timeLimit) {
            qestion.userTime = this.timeSpend;
        }
        else if(this.timeLeft > 0) {
            qestion.userTime = qestion.timeLimit - qestion.userTimeLeft;
        }

        $next.find('.time').text(this.get_elapsed_time_string(qestion.userTime));
        
        //reset time
        this.resetClock();
    }

    ovarlaySummary() {
        $('#ast-test-header').hide();
        let time = 0;
        let correctAnswers = 0;

        $.each(this.questions, (index, value) => {

            //Question
            const $qSum = $(`#${value.id}-FS`);
            time += value.userTime;

            $qSum.find('.time').text(this.get_elapsed_time_string(value.userTime));
            $qSum.find('.userScore').text(this.pretty_number_string(value.userScore));
            $qSum.find('.tatalQestions').text(this.pretty_number_string(value.totalSubquestions));
            
            //Sub Questioons
            $.each(value.allQuestions, (i, question) => {
                $.each(question.allSubquestions, (k, subQuestion) => {
                    //Correct answer
                    if(subQuestion.correctAnswer === subQuestion.userAnswer) {
                        $(`#${subQuestion.id}-${subQuestion.userAnswer}`).addClass('correct');
                        correctAnswers++;
                    }
                    //Incorect answer
                    else {
                        $(`#${subQuestion.id}-${subQuestion.correctAnswer}`).addClass('correct-answer');
                        $(`#${subQuestion.id}-${subQuestion.userAnswer}`).addClass('incorrect');
                    }
                });
            });
        });

        $('#SUM').find('.time-f').text(this.get_elapsed_time_string(time));
        $('#SUM').find('.userScore-f').text(this.pretty_number_string(correctAnswers));
    }

    scrollEvents() {
        $(window).scroll(function () {

            var s = $(window).scrollTop();
            if (s > 0) {
                $("#airman-selection-test-20-timer").addClass("fixed");
            }
            else {
                $("#airman-selection-test-20-timer").removeClass("fixed");
            }
        });
    }

    sliderEvents() {

        $("input[type=range].slider").val(0);

        $('input[type=range].slider').on('input', function () {

            var id = $(this).attr("data-slider-for");
            var range_value = $(this).val();
            var scroll_element = $("#" + id + " .overflow-child");
            
            var w = $(window).width(); 
            //var scroll_element_width = scroll_element.width();
            //var scroll_element_width = 744 + 75;
            var scroll_element_width = scroll_element.width() + 76; // AARON INCREASED THIS BT JUST 1 AS THATS ALL THAT SEEMED TO BE NEEDED

            var travel_value = scroll_element_width - w;
            travel_value = Math.floor(travel_value / 100);
            travel_value = travel_value * range_value;
            //travel_value += 50;
            
            $(scroll_element).css("margin-left", "-" + travel_value + "px");
            //console.log(id + " " + range_value + " " + scroll_element_width + " " + travel_value);
        });


        $(window).resize(function () {
            $('.overflow-child').css("margin-left", "0px");
            $("input[type=range].slider").val(0);
        });
        

    }

    get_elapsed_time_string(total_seconds) {

		let hours = Math.floor(total_seconds / 3600);
		total_seconds = total_seconds % 3600;

		let minutes = Math.floor(total_seconds / 60);
		total_seconds = total_seconds % 60;

		let seconds = Math.floor(total_seconds);

		// Pad the minutes and seconds with leading zeros, if required
		hours = this.pretty_number_string(hours);
		minutes = this.pretty_number_string(minutes);
		seconds = this.pretty_number_string(seconds);

		// Compose the string for display
		const currentTimeString = minutes + ":" + seconds;

		return currentTimeString;
    }
    
    pretty_number_string(num) {
        return ( num < 10 ? "0" : "" ) + num;
    }
}