/*import 'fullpage.js';*/

export default class {
    /*
    constructor() {
        this.slider = $('#hero-sliding-sections');
        this.nextSlideArrow = $('.next-slide-20');
        this.nextSlide = $('.hero-section-home-20-nav .next');
        this.prevSlide = $('.hero-section-home-20-nav .prev');
        this.slideIndex = $('.hero-section-home-20-nav .current-slide');
        
        this.browserSet();

        //$.fn.fullpage.destroy('all')
        this.fullPage();
        this.clickEvents();
    }

    browserSet() {        
        var isIE = this.isIE();
        $('.CloudflareSlide').each(function(){
            var cloudflareContainer = $(this).find('.CloudflareContainer');
            if (cloudflareContainer.length > 0) {
                if (isIE) {
                    cloudflareContainer.remove();
                } else {
                    $(this).find('.CloudflareAlternative').remove();
                }                
            }            
        });        
    }

    fullPage() {
        this.slider.fullpage({
            css3: false,
            easingcss3: 'easeInOutCubic',
            onLeave: (index, nextIndex, direction) => {
                let newIndex = 1

                if(direction == 'down') {
                    newIndex = index + 1;        
                    this.slideIndex.text(newIndex);
                }
                else {
                    newIndex = index - 1;
                    this.slideIndex.text(newIndex);
                }     
                if(newIndex === 1) {
                    $('.hero-section-home-20-nav').hide();
                }
                else {
                    $('.hero-section-home-20-nav').show();
                }          
            },
            onSlideLeave: (anchorLink, index, slideIndex, direction, nextSlideIndex) => {

             
            }
        });
    }

    clickEvents() {
        this.nextSlideArrow.on('click', (e) => {
            e.preventDefault();
            $.fn.fullpage.moveSectionDown();
        });

        this.nextSlide.on('click', (e) => {
            e.preventDefault();
            $.fn.fullpage.moveSectionDown();
        });

        this.prevSlide.on('click', (e) => {
            e.preventDefault();
            $.fn.fullpage.moveSectionUp();
        });
    }

    isIE () {
        console.log('navigator.appName == ' + navigator.appName + ', navigator.userAgent = ' + navigator.userAgent);
        return ((navigator.appName == 'Microsoft Internet Explorer') || ((navigator.appName == 'Netscape') && (new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})").exec(navigator.userAgent) != null)));
    }
    */
}