
export default class {

    constructor() {
        if ($('#CloudflarePlayer').length) {
            let self = this;
            $.getScript('https://embed.videodelivery.net/embed/sdk.latest.js').done(function() {
                self.init();
            });
        }
    }

    init() {
        const player = Stream(document.getElementById('CloudflarePlayer'));

        $('.hero-video-pauseplay').on('click', function() {
            if ($('.hero-video-pauseplay').hasClass('paused')) {
                player.play();
                $('.hero-video-pauseplay').attr('aria-label', 'Pause background video');
            } else {
                player.pause();
                $('.hero-video-pauseplay').attr('aria-label', 'Play background video');
            }
            $('.hero-video-pauseplay').toggleClass('paused');
        }); 

        $(window).on('scroll', function() {  

            var playerElement = $('#CloudflarePlayer');
            var top_of_element = playerElement.offset().top;
            var bottom_of_element = playerElement.offset().top + playerElement.outerHeight();
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();
    
            if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
                // the element is visible
                if (!$('.hero-video-pauseplay').hasClass('paused')) {
                    player.play();
                }
            } else {
                // the element is not visible
                player.pause();
            }
        });

    }
}
