import Utitilities from '../../helpers/utilities';

export default class {
    constructor() {
        this.utitilities = new Utitilities();
        this.makeScroll();
    }

    makeScroll() {
        const hash = window.location.hash;
        if(window.location.hash) {
            setTimeout(() => {
                if(hash !== '#regular' || hash !== '#reserve') {
                    const $target = $(hash);
                    this.utitilities.scrollTo($target, 100);
                }
            }, 1000); 
        }        
    }


}