export default class {
    constructor() {
        this.modalOpenButton = $('.modal-open');
        this.modalCloseButton = $('.modal-close');
        this.modalContainer = $('.modal');
        this.openModalEvants();
        this.closeModalEvents();

    }

    openModalEvants() {
        this.modalOpenButton.on('click', (e) => {
            e.preventDefault();
            const $el = $(e.currentTarget);
            const id = $el.attr('href');
            const $modal = $(id);

            $modal.addClass('opened');
        });
    }

    closeModalEvents() {
        this.modalContainer.on('click', (e) => {
            const $el = $(e.currentTarget);
            const dialog = $('.modal-dialog');
            if (!dialog.is(e.target) && dialog.has(e.target).length === 0) 
            {
                e.preventDefault();
                $el.removeClass('opened');
            }
        });
        this.modalCloseButton.on('click', (e) => {
            e.preventDefault();
            const $el = $(e.currentTarget);
            const id = $el.data('id');
            const $modal = $(`#${id}`);

            $modal.removeClass('opened');
        });
    }
}