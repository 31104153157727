
export default class {

    constructor() {
        
        this.clickEvents();
    }

    clickEvents() {
        /*
        $(".navbar-toggle").click(function (event) {
            event.preventDefault();
            $(this).toggleClass("show");
            $(".main-navigation").toggleClass("show");    
            $(".page-header").toggleClass("expand");    
        });
        $(".mobile-chevron").click(function (event) {
            event.preventDefault();
            $(this).toggleClass("expand");
            $(this).parent().children(".nav-list-sub").toggleClass("show");
        });
        $(".header-search-fake-cta").click(function (event) {
            event.preventDefault();
           $(".header-search-real-cta").click();
        });

        $(".desktop-search-li a").click(function (event) {
            event.preventDefault();
           $(".mobile-search-li").addClass("show-on-desktop");
           $('#header-search-form .input-container input').focus();
        });

        $(".search-close-button").click(function (event) {
            event.preventDefault();
           $(".mobile-search-li").removeClass("show-on-desktop");
        });
        */
    }
    
}