import CalculateDistance from '../lib/calculate-distance';
import googleLoader from '../lib/google-loader';
import Utilities from '../helpers/utilities';
import RoleFavouriting  from './roleFavouriting';

export default class {

    constructor() {
        
        this.breakpoint = 1440;
        this.counter = $('.filtered-roles-count');
        this.roleFinder = $('.role-finder-results');
        this.bases = this.roleFinder.data('bases');
        this.currentQualification = '';
        this.roleFavouriting = new RoleFavouriting();
        this.noFavsBlock = $('.no-favourites');
        this.noFavsBlock.hide();
        this.setEvents();
        this.roletypeToggle(); // will also update the counter etc

        var preselectedQualification = new Utilities().getUrlVars()['qualification'];
        switch(preselectedQualification) {
            case 'university-degree':
                $('input[name=qualification][value="University degree"]').prop('checked', true);    
                $('input[type=radio][name=qualification]').trigger('change');
                break;
            case 'a-levels':
                $('input[name=qualification][value="A-levels"]').prop('checked', true);          
                $('input[type=radio][name=qualification]').trigger('change');
                break;
            case 'gcses':
                $('input[name=qualification][value="GCSES"]').prop('checked', true);          
                $('input[type=radio][name=qualification]').trigger('change');
                break;
            case 'no-qualifications':
                $('input[name=qualification][value="No qualifications"]').prop('checked', true);          
                $('input[type=radio][name=qualification]').trigger('change');
                break;
        }

        if ($('.role-finder-filter-container').data('filterstartsopen') == 'True') {
            if ($(window).width() < this.breakpoint)
                $('.role-finder-filter-container-mobileheader').trigger('click');
            $('.role-finder-filter-dropdownwrapper[data-filter="age"]').find('.role-finder-filter-dropdown').trigger('click');
        }                  
    }

    setEvents() {
        var self = this;

        $(window).on('resize', function(){
            self.moveFilter();
            self.showFiltersOnResizeToDesktop();
        });
        self.moveFilter();

        if ($(window).width() < this.breakpoint) {
            $('.role-finder-filter-wrapper').hide();
            $('.role-finder-filter-clearfilters').hide();
        }

        $('.role-finder-filter-container-mobileheader').on('click', function() {
            var shown = $('.role-finder-filter-wrapper').is(":visible");
            if (shown) {
                $('.role-finder-filter-wrapper').slideUp();
                $('.role-finder-filter-clearfilters').slideUp();
                $('.role-finder-filter').hide();
                $('.role-finder-filter-dropdownwrapper').removeClass('active');                   
                $('.role-finder-filter-container-mobileheader-image[data-imagetype="close"]').hide();
                $('.role-finder-filter-container-mobileheader-image[data-imagetype="filter"]').show();
            } else {    
                $('.role-finder-filter-wrapper').slideDown();    
                $('.role-finder-filter-clearfilters').slideDown();
                $('.role-finder-filter-container-mobileheader-image[data-imagetype="filter"]').hide();
                $('.role-finder-filter-container-mobileheader-image[data-imagetype="close"]').show();
            }
        });        

        $('.roletype-toggle-checkbox').on('click', function() {
           self.roletypeToggle();
        });

        $('.role-finder-filter-dropdown').on('click', function() {
            if ($(this).parent().hasClass('disabled'))
                return false;
            self.showFilter($(this).parent());
        });

        this.ageEvents();
        this.qualificationEvents();
        this.interestEvents();
        this.locationEvents();
        this.sortTypeEvents();

        $('.role-finder-filter-clearfilters').on('click', function() {
            self.clearFilters();
        });

        $('#favouritesCheckbox').on('click', (e) => {            
            this.sortResults();
            this.showHideNoFavouritesBlock();            
        });
    }
    
    showHideNoFavouritesBlock() {                
        if (this.noFavsBlock.length > 0) {
            if ($('#favouritesCheckbox').is(':checked')) {
                let favFound = false;
                $('.favourite-button').each(function() {
                    if (!$(this).prop('adds'))
                        favFound = true;
                });
                if (favFound) {
                    this.noFavsBlock.hide();
                } else {
                    this.noFavsBlock.show();
                }
            } else {            
                this.noFavsBlock.hide();
            }
        }        
    }

    showFiltersOnResizeToDesktop() {
        if ($(window).width() >= this.breakpoint) {
            $('.role-finder-filter-wrapper').show();
            $('.role-finder-filter-clearfilters').show();
        }
    }

    ageEvents() {
        var self = this;       
        $('#AgeSlider').on('input change', function() {
            self.ageChanged();
        });    
        $('#AgeMinus').on('click', function() {
            var ageSlider = document.getElementById('AgeSlider');
            if (ageSlider.value > ageSlider.min) {
                ageSlider.stepDown();
                self.ageChanged();
            }
        });
        $('#AgePlus').on('click', function() {
            var ageSlider = document.getElementById('AgeSlider');
            if (ageSlider.value < ageSlider.max) {
                if ($('#AgeSliderValue').html() == '?') {
                    ageSlider.value == 16;
                } else {
                    ageSlider.stepUp();
                }                
                self.ageChanged();
            }
        });
    }

    ageChanged() {
        if ($('#AgeSlider').val() == 0) {
            $('#AgeSliderValue').html('?');
        } else {
            $('#AgeSliderValue').html($('#AgeSlider').val());
        }
        
        $('.role-finder-filter-dropdownwrapper[data-filter="age"]').find('.additional-label').text('(' + $('#AgeSlider').val() + ' yrs)' );
        this.filterResults();
    }

    qualificationEvents() {
        var self = this;
        $('input[type=radio][name=qualification]').on('click', function() {                        
            var selectedValue = $(this).val();            
            if (selectedValue == self.currentQualification) {
                $(this).prop('checked', false);
                self.currentQualification = '';
                $('.role-finder-filter-dropdownwrapper[data-filter="qualification"]').find('.additional-label').text('');        
                self.filterResults();
            }            
        });
        $('input[type=radio][name=qualification]').on('change', function() {     
            self.currentQualification = $('input[name="qualification"]:checked').val(); 
            $('.role-finder-filter-dropdownwrapper[data-filter="qualification"]').find('.additional-label').text('(1)');        
            self.filterResults();
        });
    }

    interestEvents() {
        var self = this;
        $('.interests-checkbox').on('change', function() {
            self.interestsChanged();            
        });
    }

    locationEvents() {

        var self = this;
        
        $("#LocationInput").on('keypress', function(e) {
            if (e.which == 13) {
                self.searchBases();
            }
        });

        $('.locations-form-submit').on('click', function(e) {
            e.preventDefault();
            self.searchBases();
        });

        $('#LocationResults').on('click', '.role-finder-basechip', function() {
            $(this).toggleClass('active');
            var selectedCount = $('.role-finder-basechip.active').length;
            if (selectedCount > 0) {
                $('.role-finder-filter-dropdownwrapper[data-filter="location"]').find('.additional-label').text('(' + selectedCount + ')');        
            } else {
                $('.role-finder-filter-dropdownwrapper[data-filter="location"]').find('.additional-label').text('');        
            }
            self.filterResults() 
        });
    }

    sortTypeEvents() {
        var self = this;
        $('.sort-type').on('click', function() {
            $('.sort-type-wrapper').toggleClass('active');
            $('.sort-options').toggle();
            var arrow = $(this).find('img');
            if (arrow.hasClass('rotate90')) {
                arrow.addClass('rotate270');
                arrow.removeClass('rotate90');                
            } else {
                arrow.addClass('rotate90');
                arrow.removeClass('rotate270')
            }
        });
        $('.sort-option').on('click', function() {
            $('.sort-option').removeClass('selected');
            $(this).addClass('selected');
            $('.sort-type-text').text($(this).text());
            $('.sort-type').trigger('click');            
            self.sortResults();
        });
    }

    searchBases() {                    
        googleLoader().then(google => {
            this.calculateDistance = new CalculateDistance(google);    
            const getBases = async (retries = 0) => {        
                if (this.calculateDistance) {                    
                    try {                    
                        const results = await this.calculateDistance.selectBasesInRange($('#LocationInput').val(), this.bases, 65);
                        this.removeBaseChips();
                        if (results) {
                            this.addBaseChipsHeader();
                            results.forEach((val) => this.addBaseChip(val.Title, val.Distance));    
                        }
                        this.filterResults();                 
                    } catch(error) {
                        this.removeBaseChips();
                        console.error(error);
                    }
                } else {
                    if (retries <= 50) {
                        setTimeout(async () => {
                            await getBases(retries + 1);
                        }, 200);
                    }
                }
            }            
            getBases();                                
        });
    }

    removeBaseChips() {
        $('#LocationResults').html('');
    }

    addBaseChipsHeader() {
        var header = '<div class="role-finder-basechips-header">Filter by the RAF bases near you:</div>';
        $('#LocationResults').append(header);
    }

    addBaseChip(name, distance) {
        var distanceDisplay = '(' + distance.slice(0, -1) + ' miles)'
        var newChip = '<div class="role-finder-basechip" data-basename="' + name + '"><div class="role-finder-basechip-name">' + name + '</div><div class="role-finder-basechip-distance">' + distanceDisplay + '</div></div>'
        $('#LocationResults').append(newChip);
    }

    showFilter($selectedFilterParent) {     
        if ($selectedFilterParent.hasClass('active')) {
            $selectedFilterParent.removeClass('active');
            $('.role-finder-filter[data-filter="' + $selectedFilterParent.data('filter') + '"]').slideUp();   
        } else {                               
            $('.role-finder-filter-dropdownwrapper').removeClass('active');
            $selectedFilterParent.addClass('active'); 

            if ($(window).width() >= this.breakpoint) {
                if ($('.role-finder-filter:visible').length > 0)
                {                
                    $('.role-finder-filter').hide();                   
                    $('.role-finder-filter[data-filter="' + $selectedFilterParent.data('filter') + '"]').show();
                } else {
                    $('.role-finder-filter').hide();                   
                    $('.role-finder-filter[data-filter="' + $selectedFilterParent.data('filter') + '"]').slideDown();
                }      
            } else {
                $('.role-finder-filter').slideUp();                        
                $('.role-finder-filter[data-filter="' + $selectedFilterParent.data('filter') + '"]').slideDown();
            }
        }   
    }

    moveFilter() {
        var self = this;
        $('.role-finder-filter').each(function() {            
            if ($(window).width() >= self.breakpoint) {
                $('.role-finder-filter-selection-container').append($(this));
            } else {
                $('.role-finder-filter-dropdownwrapper[data-filter="' + $(this).data('filter') + '"]').first().after($(this));                
            }
        })
    }

    interestsChanged() {
        var max = 3;
        var count = $('.interests-checkbox:checkbox:checked').length;
        
        if (count == max) {
            $('.interests-checkbox-desktop:checkbox:not(:checked)').attr('disabled', true);            
            $('.interests-checkbox:checkbox:not(:checked)').attr('disabled', true);            
        } else {
            $('.interests-checkbox-desktop:checkbox').removeAttr('disabled');
            $('.interests-checkbox:checkbox').removeAttr('disabled');
        }

        if (count == 0) {
            $('.role-finder-filter-dropdownwrapper[data-filter="interests"]').find('.additional-label').text('');               
            this.roleFinder.prop('data-interests', '');  
        } else {
            $('.role-finder-filter-dropdownwrapper[data-filter="interests"]').find('.additional-label').text('(' + count + ')');   
            var interests = '';
            $('.interests-checkbox:checkbox:checked').each(function() {
                interests += $(this).val();
                interests += ',';
            });
            this.roleFinder.prop('data-interests', interests.substring(0, interests.length - 1));
        }

        this.sortResults();
    }

    roletypeToggle() {
        if ($('.roletype-toggle-checkbox').is(':checked')) {
            $('.roletype-toggle').addClass('reserves');
            $('.role-finder-result--link').each(function() {
                var href = $(this).attr('href');
                $(this).attr('href', href + '?time=reserve');
            });
            this.enableFilter('location', true);
        } else {
            $('.roletype-toggle').removeClass('reserves');   
            $('.role-finder-result--link').each(function() {
                var href = $(this).attr('href');
                if (href.indexOf('?') !== -1) {
                    $(this).attr('href', href.split('?')[0]);
                 }                 
            });
            this.enableFilter('location', false);
        }
        this.filterResults();
    }

    enableFilter(filter, enable) {
        if (enable) {
            $('.role-finder-filter-dropdownwrapper[data-filter="' + filter + '"]').removeClass('disabled');
        } else {
            $('.role-finder-filter[data-filter="' + filter + '"]').hide();
            $('.role-finder-filter-dropdownwrapper[data-filter="' + filter + '"]').addClass('disabled');                    
            $('.role-finder-filter-dropdownwrapper[data-filter="' + filter + '"]').removeClass('active'); 
        }
    }

    filterResults() {
        var self = this;
        self.setTotalFilteredCount()
        $('.role-finder-result').each(function() {
            var roleResult = $(this);
            if (self.roleHasRegularOrReserve(roleResult) &&
                self.roleHasAge(roleResult) &&
                self.roleHasQualification(roleResult) &&
                self.roleHasLocation(roleResult)) {
                roleResult.show();
            } else {
                roleResult.hide();
            }
        });
        self.updateCounter();
        self.sortResults();
    }

    setTotalFilteredCount() {
        var ageFiltered = $('#AgeSliderValue').html() == '?' ? 0 : 1;
        var qualificationFiltered = this.currentQualification != '' ? 1 : 0;
        var interestFiltered = $('.interests-checkbox:checkbox:checked').length;
        var locationFiltered = $('.role-finder-basechip.active').length;
        var totalCount = ageFiltered + qualificationFiltered + interestFiltered + locationFiltered;

        if (totalCount > 0) {
            $('.total-filtered-count').text('(' + totalCount + ' applied)');
        } else {
            $('.total-filtered-count').text('');
        }
    }

    roleHasRegularOrReserve(roleResult) {      
        if ($('.roletype-toggle-checkbox').is(':checked')) {
            return roleResult.data('showforreserve') == 'True';
        } else {
            return roleResult.data('showforregular') == 'True'
        }  
    }

    roleHasAge(roleResult) {
            
        if ($('#AgeSliderValue').html() == '?')
            return true;

        var selectedAge = $('#AgeSlider').val();
        
        if ($('.roletype-toggle-checkbox').is(':checked')) {
            return roleResult.data('agesreserve').split(',').includes(selectedAge);
        } else {
            return roleResult.data('agesregular').split(',').includes(selectedAge);
        }  
    }

    roleHasQualification(roleResult) {
        var selectedQualification = $('input[name="qualification"]:checked').val();
        if (selectedQualification == undefined)
            return true;
        return roleResult.data('qualifications').toLowerCase().split(',').includes(selectedQualification.toLowerCase());
    }

    roleHasLocation(roleResult) {
        if (!$('.roletype-toggle-checkbox').is(':checked')) {
            return true;
        }

        var selectedBases = $('.role-finder-basechip.active');
        if (selectedBases.length < 1) {
            return true;
        }

        var include = false;
        $('.role-finder-basechip.active').each(function() {
            if (roleResult.data('bases').toLowerCase().split(',').includes($(this).data('basename').toLowerCase())) {
                include = true;
                return false;
            }
        })
               
        return include;
    }

    updateCounter() {
        this.counter.text($('.role-finder-result:visible').length);        
    }

    clearFilters() {

        // Age
        $('#AgeSlider').val(0);
        $('#AgeSliderValue').html('?');

        // Qualification 
        $('input[name="qualification"]:checked').prop('checked', false);
        
        // Interests
        $('.interests-checkbox:checkbox:checked').prop('checked', false);
        $('.interests-checkbox').prop('disabled', false);
        this.roleFinder.prop('data-interests', '');

        // Location        
        $('#LocationInput').val('');
        this.removeBaseChips();

        $('.additional-label').text('');

        this.filterResults();
    }

    sortResults() {
        // favsort: 0 = favourite, 1 = not favourite
        switch($('.sort-type').text()) {            
            case 'A - Z': {
                var results = $('.role-finder-result');
                results.sort(function(a, b) {
                    if ($('#favouritesCheckbox').is(':checked')) {
                        var valueA = $(a).data('favsort') + $(a).data('name').toLowerCase();
                        var valueB = $(b).data('favsort') + $(b).data('name').toLowerCase();
                        return String.prototype.localeCompare.call(valueA, valueB);
                    } else {
                        return String.prototype.localeCompare.call($(a).data('name').toLowerCase(), $(b).data('name').toLowerCase());
                    }
                });
                $('.role-finder-results-container').html(results);
                break;
            }
            case 'Z - A': {
                var results = $('.role-finder-result');
                results.sort(function(a, b) {        
                    if ($('#favouritesCheckbox').is(':checked')) { 
                        var valueA = ($(a).data('favsort') == '1' ? '0' : '1') + $(a).data('name').toLowerCase();
                        var valueB = ($(b).data('favsort') == '1' ? '0' : '1') + $(b).data('name').toLowerCase();
                        return String.prototype.localeCompare.call(valueB, valueA);
                    } else {
                        return String.prototype.localeCompare.call($(b).data('name').toLowerCase(), $(a).data('name').toLowerCase());
                    }
                });
                $('.role-finder-results-container').html(results);
                break;
            }
            default: {     
                var self = this;
                $('.role-finder-result').each(function() {
                    var sortScore = self.sortScore(this);
                    $(this).prop('data-sortscore', sortScore);
                });        
                var results = $('.role-finder-result');
                results.sort(function(a, b) {            
                    return $(b).prop('data-sortscore')-$(a).prop('data-sortscore');
                });
                $('.role-finder-results-container').html(results);
                break;
            }
        }
        this.roleFavouriting.setFavourites();
    }
    
    sortScore(roleResult) {
        roleResult = $(roleResult);          
        var score = roleResult.data('rolesortingindex'); 

        var qualificationMultipler = 1000;
        var selectedQualification = $('input[name="qualification"]:checked').val();   
        if (selectedQualification != undefined && selectedQualification != '') {            
            switch (roleResult.data('qualificationaim')) {
                case "University degree":
                    qualificationMultipler = 5000;
                    break;
                case "A-levels":
                    qualificationMultipler = 2000;
                    break;
            }
            score = score + (2.5 * qualificationMultipler);
        }
          
        var selectedInterests = this.roleFinder.prop('data-interests');
        if (selectedInterests != undefined && selectedInterests != '') {
            selectedInterests = selectedInterests.split(',');
            $.each(roleResult.data('interests').split(','), function() {
                if ($.inArray(this, selectedInterests) != -1) {
                    score = score + qualificationMultipler;
                }
            });
        }

        if ($('#favouritesCheckbox').is(':checked') && roleResult.data('favsort') == '0') {
            score = score + 100000;
        }

        return score;
    }
}
