
export default class {

  constructor() {

    this.clickEvents();

  }

  clickEvents() {
    
    $(".events-20 .sort-by a").click((e) => {
        e.preventDefault();
        const $target = $(e.currentTarget);
        const filter = $target.data('show');

        if ($target.hasClass("sort-toggle")){
          $target.parent().toggleClass("show");
        }
        else if ($target.hasClass("sort-close")){
          $target.parent().parent().parent().removeClass("show");
		}	
		
		if(filter !== undefined) {
			this.togleFilter(filter);
		}
    });
  }

  togleFilter(selected_option) {
    if (selected_option == "all") {
        $(".event-20").show();
    }
    else {
        $(".event-20").hide();
        $(".event-20." + selected_option).show();
    }
  }

}