export default class {
    constructor() {

    }

    isAvailable() {
        setTimeout(() => {
            if ((window._gaq && window._gaq._getTracker) || window.urchinTracker) {
                return true;
            } else {
               return false;
            }
        }, 300); 
    }

    dataLayer(_event, _name, _action, _label) {
        if(_event === undefined) {
            _event = 'eventTracking';
        }
        var data = {
            'event': _event,
            'category': _name,
            'action': _action,
            'label': _label
        };
        try {         
            dataLayer.push(data);
        }
        catch(err) {
            console.log("NO GTM ON SITE");
        }
    }

    track(eventCategory,eventAction,eventLabel,eventValue) {
        if(this.isAvailable()) {
            ga('send', 'event', eventCategory, eventAction, eventLabel, eventValue);          
        }
    }
    
    trackFb(eventName,eventParams) {
        if(typeof fbq !== 'undefined'){
            fbq('trackCustom',eventName,eventParams);
        }
    }
}