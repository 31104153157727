import Utilities from '../helpers/utilities';
export default class {
    constructor() {
        this.utilities = new Utilities();
        this.toggleButtonContainer = $(".toggle-switch");      
        this.toggleButton = $(".toggle-switch a");      
        this.pageId = $('.role-info-15').attr('id');
        this.cookieName = `role-page-1_5-${this.pageId}`;
        this.caruselLinkButton = $('.carusel-15-link-button');
        

        this.loadSwitcher();
        this.changeOnLoad();
        this.clickEvents();

        this.touchEvents();
        
    }

    clickEvents() {

        var is_touch_screen = false;
        if ("ontouchstart" in document.documentElement)
        {
            //is_touch_screen = true;
        }

        this.toggleButton.on('click', (e) => {
            e.preventDefault();
            
            if (!is_touch_screen) {
                const $el = $(e.currentTarget);         
                const show = $el.data('show');
                const hide = $el.data('hide');

                if($el.hasClass('full-time')) {
                    this.showReserveOrRegularText('regular');            
                    $el.parent().removeClass("bg-right");
                    this.utilities.setCookie(this.cookieName, 'regular');
                    this.updateCarouselLinks('regular');
                }
                else {
                    this.showReserveOrRegularText('reserve');                
                    $el.parent().addClass("bg-right");
                    this.utilities.setCookie(this.cookieName, 'reserve');
                    this.updateCarouselLinks('reserve');
                }
            
                $(`.${hide}`).hide();
                $(`.${show}`).fadeIn(100);
            }
        });

    }

    loadSwitcher() {
        if(this.utilities.smartLoad('.raf-15-role-type-switch-regular')) {
            $('.raf-15-role-type-switch-reserve').hide();
        }
        else {
            $('.raf-15-role-type-switch-reserve').show();
        }
    }

    changeOnLoad() {
        let type = this.utilities.getUrlVars()["time"];

        if(type === undefined && type === '') {
            type = window.location.hash.substr(1);
        }

        const cookie =  this.utilities.getCookie(this.cookieName);

        if(cookie !== undefined && cookie !== null && cookie !== '' && type === undefined && type === '') {
            type = cookie;
        }

        const showClass = type === 'regular' ? '.raf-15-role-type-switch-regular' : '.raf-15-role-type-switch-reserve';
        const hideClass = type === 'regular' ? '.raf-15-role-type-switch-reserve' : '.raf-15-role-type-switch-regular';

        if(type !== undefined && type !== '') {
            this.utilities.setCookie(this.cookieName, type);

            setTimeout(() => {
                $(hideClass).hide();
                $(showClass).fadeIn(100);
            }, 50);
            
    
            if(type === 'regular') {
                this.toggleButton.parent().removeClass("bg-right");
            }
            else {
                this.toggleButton.parent().addClass("bg-right");
                
            }

            this.updateCarouselLinks(type);
        }
    }

    updateCarouselLinks(time) {
        let url = this.caruselLinkButton.attr('href');
        if (url != undefined) {
            if (time === 'reserve') {
                url = `${url}#reserve`;    
            }
            else {
                if (url.includes('#reserve')) {
                    url = url.replace('#reserve', '');
                }
            }
            this.caruselLinkButton.attr('href', url);
        }
    }

    touchEvents() {
        var startX = 0;
        var touch_init_threshold = 20;

        this.toggleButtonContainer.on('touchstart', (event) => {
            startX = event.originalEvent.touches[0].pageX;                       
        });

        this.toggleButtonContainer.on('touchmove', (event) => {
            const $el = $(event.currentTarget);
            var moveX = event.originalEvent.touches[0].pageX;

            if (moveX > (startX + touch_init_threshold)) {
                $el.addClass("bg-right");
                this.utilities.setCookie(this.cookieName, 'reserve');
                $(`.raf-15-role-type-switch-regular`).hide();
                this.showReserveOrRegularText('reserve');
                $(`.raf-15-role-type-switch-reserve`).fadeIn(100);
            }
            else if (moveX < (startX - touch_init_threshold)) {
                $el.removeClass("bg-right");
                this.utilities.setCookie(this.cookieName, 'regular');
                $(`.raf-15-role-type-switch-reserve`).hide();
                this.showReserveOrRegularText('regular');
                $(`.raf-15-role-type-switch-regular`).fadeIn(100);
            }
        });
    }

    showReserveOrRegularText(selection) {
        // Hacky but need to control an element outside of the scope :/
        if (selection == 'regular') {
            $('.reserve-display').hide();
            $('.regular-display').show();
        }
        else {
            $('.regular-display').hide();
            $('.reserve-display').show();
        }
    }

}