import Analytics from '../helpers/analytics';
import Utilities from '../helpers/utilities';
import 'jquery-mask-plugin';

import applyprogress from './applyprogress';

export default class {

    constructor() {
        this.firstLoad = true;
        this.analytics = new Analytics();
        this.utilities = new Utilities();

        if (this.utilities.smartLoad('.registration-form-2018')) {
            this.registrationForm = $('.registration-form-2018');
            this.serviceCheckButton = $('.service-toggle');
            this.currentServiceCheckButton = $('.currently-toggle-2017');            
            this.registrationFormEvents();
            this.genderSelfDescribe();      
            this.dateComponentEntry();
            this.nextButtonClickEvent();
            this.onSubmit();
        }
    }

    dateComponentEntry() {        
        var self = this;
        $('.dobcomponent').on('focusout', function() {
            self.buildDob();
        });

        // User cannot see this box but we use it programatically
        // Prevent tabbing to and changing values by accident
        $('.gtm-apply-date-of-birth').on('keydown', function(e) {
            e.preventDefault();
            return false;
         });
    }

    buildDob() {
        $('.gtm-apply-date-of-birth').val($('#dobDay').val() + '-' + $('#dobMonth').val() + '-' + $('#dobYear').val());
        
        /*
        if age-90 days or above, go through unhindered.
        if age between -90 and -365 days give soft validation message (e.g. sorry, not quite old enough, please get in touch)        
        if ageless than -365 days, give hard validation message (eg sorry you are too young to apply)
        */
        var dobEmptyValidationField = $('span[data-valmsg-for="Dob"]');
        dobEmptyValidationField.addClass('field-validation-error');
        dobEmptyValidationField.removeClass('field-validation-valid');
        dobEmptyValidationField.html('<span id="Dob-error" class="">Please enter your date of birth</span>');

        var dobValidationField = $('span[data-valmsg-for="DateValidation"]');
        dobValidationField.removeClass('field-validation-error');
        dobValidationField.addClass('field-validation-valid');
        dobValidationField.text('');

        if ($('#dobDay').val() != '' && $('#dobMonth').val() != '' && $('#dobYear').val() != '')
        {
            dobEmptyValidationField.removeClass('field-validation-error');
            dobEmptyValidationField.addClass('field-validation-valid');
            dobEmptyValidationField.text('');

            dobValidationField.removeClass('field-validation-error');
            dobValidationField.addClass('field-validation-valid');
            dobValidationField.text('');

            var enteredDobString = $('#dobYear').val() + '-' + $('#dobMonth').val() + '-' + $('#dobDay').val();
            if (isNaN(Date.parse(enteredDobString)) ||
                isNaN($('#dobYear').val()) || 
                isNaN($('#dobMonth').val()) ||
                isNaN($('#dobDay').val())) {
                dobValidationField.addClass('field-validation-error');
                dobValidationField.html('<span id="DateValidation-error" class>Entered date is not valid</span>');
            } else {
                var enteredDob = new Date(enteredDobString);
                var minAgeDob = new Date();
                minAgeDob.setMonth(new Date().getMonth() - $('#AgeValues').data('minage') * 12);                
                var daysDifference = (minAgeDob - enteredDob) / (1000 * 3600 * 24);
                if (daysDifference > -90) {
                    // Can apply
                } else {
                    dobValidationField.addClass('field-validation-error');
                    dobValidationField.removeClass('field-validation-valid');
                    if (daysDifference > -365) {                        
                        dobValidationField.html('<span id="DateValidation-error" class>' + $('#AgeValues').data('softmessage') + '</span>');
                    } else {
                        dobValidationField.html('<span id="DateValidation-error" class>' + $('#AgeValues').data('hardmessage') + '</span>');
                    } 
                }                                       
            }
        }
    }

    registrationFormEvents() {
        this.eligibilityCheck();        
        this.serviceCheck();
    }

    genderSelfDescribe() {

        // trigger events
        $("#GenderSelfDescribeArea").hide();
        $("#GenderSelfDescValidation").hide();

        $("#Gender").on('change', function() {            
            if (this.value == "PreferToSelfDescribe") {
                $("#GenderSelfDescribeArea").show();
            }
            else {
                $("#GenderSelfDescribeArea").find("input[type=text]").val("");
                $("#GenderSelfDescribeArea").hide();
                $("#GenderSelfDescValidation").hide();
            }
        });

        $("#genderSelfDesc").on('change', function() {
            if ($("#genderSelfDesc")[0].value == '') {
                $("#genderSelfDescValidation").show();
            }
            else
            {
                $("#genderSelfDescValidation").hide();                
            }
        });

        // page load
        if ($("#Gender")[0].value == "PreferToSelfDescribe") {
            $("#GenderSelfDescribeArea").show();

            if ($("#GenderSelfDesc")[0].value == '') {
                $("#GenderSelfDescValidation").show();
            }
        }        
    }
 
    eligibilityCheck() {
        $('.alert-panel').hide();
        $('.content[data-eligibilitystep="1"').show();
        $('.content[data-eligibilitystep="2"').hide();
        $('.content[data-eligibilitystep="3"').hide();
        this.eligibilityCheck1();
        this.eligibilityCheck2();
        this.eligibilityCheck3();
    }

    eligibilityCheck1() {

        $('input[name="eligibleAge"]').on('change', function() {
            var selection = $('input[name="eligibleAge"]:checked');
            if (selection.val() == 'yes') {       
                $('.eligibility-age-stepbutton').text('Next');
                selection.closest('.content').find('.alert-panel').hide();
            }            
            if (selection.val() == 'no') {
                $('.eligibility-age-stepbutton').text('Contact us');
                selection.closest('.content').find('.alert-panel').show();
            }
            $('.eligibility-age-stepbutton').prop('disabled', false);
        });

        $('.eligibility-age-stepbutton').on('click', function(e) {
            e.preventDefault();
            var selection = $('input[name="eligibleAge"]:checked');
            if (selection.val() == 'yes') {
                $('.content[data-eligibilitystep="1"').hide();
                $('.content[data-eligibilitystep="2"').show();
            }            
            if (selection.val() == 'no') {
                window.location.href = "/contact";
            }
        });
    }

    eligibilityCheck2() {

        $('input[name="eligibleNationality"]').on('change', function() {
            var selection = $('input[name="eligibleNationality"]:checked');
            if (selection.val() == 'yes') {       
                $('.eligibility-nationality-stepbutton').text('Next');
                selection.closest('.content').find('.alert-panel').hide();
            }            
            if (selection.val() == 'no') {
                $('.eligibility-nationality-stepbutton').text('Contact us');
                selection.closest('.content').find('.alert-panel').show();
            }
            $('.eligibility-nationality-stepbutton').prop('disabled', false);
        });

        $('.eligibility-nationality-stepbutton').on('click', function(e) {
            e.preventDefault();
            var selection = $('input[name="eligibleNationality"]:checked');
            if (selection.val() == 'yes') {
                $('.content[data-eligibilitystep="2"').hide();
                $('.content[data-eligibilitystep="3"').show();
            }            
            if (selection.val() == 'no') {
                window.location.href = "/contact";
            }
        });

    }

    eligibilityCheck3() {

        $('input[name="eligibleQualifications"]').on('change', function() {
            var selection = $('input[name="eligibleQualifications"]:checked');
            if (selection.val() == 'yes') {       
                $('.eligibility-qualifications-stepbutton').text('Next');
                selection.closest('.content').find('.alert-panel').hide();
            }            
            if (selection.val() == 'no') {
                $('.eligibility-qualifications-stepbutton').text('Contact us');
                selection.closest('.content').find('.alert-panel').show();
            }
            $('.eligibility-qualifications-stepbutton').prop('disabled', false);
        });

        $('.eligibility-qualifications-stepbutton').on('click', function(e) {
            e.preventDefault();
            var selection = $('input[name="eligibleQualifications"]:checked');
            if (selection.val() == 'yes') {
                $('.content[data-eligibilitystep="3"').hide();
                $('.step-1').hide();
                $('#register-2017-step-2').show();
                
                // Update the progress bar (different module)...
                var progress = new applyprogress();
                progress.currentStage = 3;
                progress.setStage();

            }            
            if (selection.val() == 'no') {
                window.location.href = "/contact";
            }
        });

    }

    serviceCheck() {

        var self = this;

        this.serviceCheckButton.on('click', (e) => {
            
            e.preventDefault();
            
            const $el = $(e.currentTarget);
            const $rejoiners = $('.rejoiners-serving-container');
            const $currentlyServing = $('.currently-serving-container');
            const $nextButton = $('#nextButton');      
            
            $nextButton.prop('disabled', true);

            this.serviceCheckButton.removeClass('active');
            $el.addClass('active');

            switch($el.data('value')) {
                case "Currently":
                    $rejoiners.hide();
                    $currentlyServing.show();
                    $nextButton.text('Next');
                    self.currentlyServingButtonState();
                    break;
                case "Previously":
                    $currentlyServing.hide();       
                    $rejoiners.show();       
                    $nextButton.text('Rejoiners');             
                    $nextButton.prop('disabled', false);
                    break;
                case "Never":
                    $currentlyServing.hide();                    
                    $rejoiners.hide();
                    $nextButton.text('Next');
                    $nextButton.prop('disabled', false);
                    break;                
            }
                        
            this.analytics.track('Register Your Interest', 'Serving in Armed Forces', $el.val(), $el.val());
        });

        $('#Service').on('change', (e) => {
            self.currentlyServingButtonState();
        });

        $('#auth-confirm').on('change', (e) => {
            self.currentlyServingButtonState();
        });
    }

    currentlyServingButtonState() {
        if ($('#auth-confirm').prop('checked') && $('#Service').val() != "0") {
            $('#nextButton').prop('disabled', false);
        } else {
            $('#nextButton').prop('disabled', true);
        }
    }

    nextButtonClickEvent() {
        $('#nextButton').on('click', function(e) {
            e.preventDefault();
            if ($('#serving_previously').hasClass('.active')) {
                window.location.href = '/contact';
            } else {
                $('#register-2017-step-2').hide();
                $('#register-2017-step-3').show();
            }
        });
    }

    onSubmit() {
        $('.registration-form-2017-send').on('click', function() {

            if ($('#Gender')[0].value == 'PreferToSelfDescribe' && $('#GenderSelfDesc')[0].value == '') {   
                $('#GenderSelfDescValidation').show();
                return false;
            } else {
                $('#GenderSelfDescValidation').hide();
            }

            if ($('span[data-valmsg-for="DateValidation"]').text() != '' || $('span[data-valmsg-for="Dob"]').text() != '')
                return false;

            $('.registration-form-2017-send').hide();
            $('.registration-form-2017-send-loading').addClass('show');

        });        
    }    
}
